<template>
  <div class="reviewDetails" >
    <div class="home"></div>

    <div class="reviewDetails_cen">

      <div class="danTitles">
        <span @click="skip('/')">首页</span>
        >
        <span @click="skip('/PQ')">商标查询</span>
        >
        <span class="active">驳通智写</span>
      </div>

      <div style="display: flex;justify-content: right">
        <div :style="{width: changeFlag ? '200px' : '120px'}"  class="generateClass" @click="generateReviewSave('2')">{{changeFlag ? '确认信息并生成' : '开始生成'}}</div>
      </div>

      <div class="box" >
        <div class="titles">申请商标基础信息</div>
        <div style="padding-left: 4px">
          <ul :style="{height: applyData.trademarkCategory == 2  ? '42px' : applyData.trademarkNameCategory == 2 || applyData.trademarkNameCategory == 3 ? '126px' : '84px'}" v-loading="loading2" class="trademarkInformation">
            <li class="_li">
              <p>
                <span class="name active" style="width: 100px">商标名称</span>
                <span class="span_">{{applyData.trademarkName}}</span>
              </p>
              <p style="display: flex;align-items: center">
                <span class="name" style="width: 100px;font-weight: 500;font-size: 14px;color: #000000">商标类型</span>
                <span  class="span_">
                   <el-radio-group @change="trademarkCategoryChange" class="radio-group" v-model="applyData.trademarkCategory">
                      <el-radio :label="2">图形标</el-radio>
                      <el-radio :label="1">文字标</el-radio>
                      <el-radio :label="3">图形加文字标</el-radio>
                    </el-radio-group>
                </span>
              </p>
            </li>
            <li class="_li">
              <p v-if="applyData.trademarkCategory != 2" style="display: flex;align-items: center">
                <span class="name active" style="width: 100px">商标名称类型</span>
                <span  class="span_">
                   <el-radio-group class="radio-group" v-model="applyData.trademarkNameCategory">
                      <el-radio :label="1">汉字标</el-radio>
                      <el-radio :label="2">英文标</el-radio>
                      <el-radio :label="3">组合标</el-radio>
                    </el-radio-group>
                </span>
              </p>
              <p style="display: flex;align-items: center" v-if="applyData.trademarkNameCategory == 1 || applyData.trademarkNameCategory == 3">
                <span class="name" style="width: 115px;font-weight: 500;font-size: 14px;color: #000000">汉字个数</span>
                <span v-if="flagHan" class="sheng span_">
                  {{applyData.trademarkNameChineseNumber}} <i style="margin-left: 5px;color: #067BE3;font-size: 18px" @click.stop="flagHan = false" class="el-icon-edit-outline"></i>
                </span>
                <span @click.stop class="sheng span_" v-else>
                  <el-input style="width: 100px" size="small" v-model="applyData.trademarkNameChineseNumber" placeholder="请输入汉字个数"></el-input>
                </span>
              </p>
            </li>
            <li v-if="applyData.trademarkNameCategory == 2 || applyData.trademarkNameCategory == 3" class="_li">
              <p style="display: flex;align-items: center">
                <span class="name active" style="width: 100px">英文组成</span>
                <span class="span_">
                   <el-radio-group class="radio-group" v-model="applyData.englishNameCategory">
                      <el-radio :label="1">词组</el-radio>
                      <el-radio :label="2">字母</el-radio>
                    </el-radio-group>
                </span>
              </p>
              <p>
                <span class="name" style="width: 115px;font-weight: 500;font-size: 14px;color: #000000">字母字数</span>
                <span v-if="flagMu"  class="sheng span_">{{applyData.englishLetterNumber}} <i style="margin-left: 5px;color: #067BE3;font-size: 18px" @click.stop="flagMu = false" class="el-icon-edit-outline"></i></span>
                <span @click.stop class="sheng span_" v-else>
                  <el-input style="width: 100px;" size="small" v-model="applyData.englishLetterNumber" placeholder="请输入汉字个数"></el-input>
                </span>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div style="height: 540px" class="box">
        <div class="titles">引证商标信息</div>
        <vxe-grid
          :row-config="{height: 100}"
          :cell-class-name="cellClassName"
          v-loading="loading3"
          class="vxe_table"
          :header-cell-class-name="headerCellClassName"
          @edit-activated="editActivateds"
          @edit-closed="editCloseds"
          align="center"
          show-overflow
          height="430"
          ref="tableRef"
          :loading="loadingTable"
          :edit-config="{trigger: 'click', mode: 'cell', showIcon:false, beforeEditMethod: handleCellClick }"
          :columns="columns"
          :data="gridOptions1" border>
          <template  #value="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分'" >
                    {{row.value}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '采取措施'" >
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
                  </span>
            <span v-else-if="row.field == '注册日期'" >
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                  </span>
            <span v-else>
                    {{row.value}}
                  </span>
          </template>
          <template  #value1="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value1}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value1)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value1}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value1}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value1}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '采取措施'" >
                    <el-radio-group  @change="e => modifyChange(e,0)" :class="[{active: columns.length == 3}]" v-model="row.value1">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
              {{row.value1 == 1 ? '否' : row.value1 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[0]" :class="[{active: columns.length == 3}]" v-model="row.value1">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[0]" :class="[{active: columns.length == 3}]" v-model="row.value1">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value1}}
                  </span>
          </template>
          <template  #value2="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value2}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value2)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value2}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value2}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value2}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,1)" :class="[{active: columns.length == 3}]" v-model="row.value2">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
              {{row.value2 == 1 ? '否' : row.value2 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
  <el-radio-group :disabled="disabledArr[1]" :class="[{active: columns.length == 3}]" v-model="row.value2">
  <el-radio :label="true">论述</el-radio>
  <el-radio :label="false">不论述</el-radio>
  </el-radio-group>
  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
  <el-radio-group :disabled="disabledArr[1]" :class="[{active: columns.length == 3}]" v-model="row.value2">
  <el-radio :label="true">论述</el-radio>
  <el-radio :label="false">不论述</el-radio>
  </el-radio-group>
  </span>
            <span v-else>
                    {{row.value2}}
                  </span>
          </template>
          <template  #value3="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value3}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value3)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value3}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value3}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value3}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,2)" :class="[{active: columns.length == 3}]" v-model="row.value3">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
              {{row.value3 == 1 ? '否' : row.value3 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[2]" :class="[{active: columns.length == 3}]" v-model="row.value3">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[2]" :class="[{active: columns.length == 3}]" v-model="row.value3">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value3}}
                  </span>
          </template>
          <template  #value4="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value4}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value4)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value4}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value4}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value4}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,3)" :class="[{active: columns.length == 3}]" v-model="row.value4">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
            {{row.value4 == 1 ? '否' : row.value4 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[3]" :class="[{active: columns.length == 3}]" v-model="row.value4">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[3]" :class="[{active: columns.length == 3}]" v-model="row.value4">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value4}}
                  </span>
          </template>
          <template  #value5="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value5}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value5)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value5}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value5}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value5}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,4)" :class="[{active: columns.length == 3}]" v-model="row.value5">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
                  {{row.value5 == 1 ? '否' : row.value5 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[4]" :class="[{active: columns.length == 3}]" v-model="row.value5">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[4]" :class="[{active: columns.length == 3}]" v-model="row.value5">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value5}}
                  </span>
          </template>
          <template  #value6="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value6}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value6)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value6}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value6}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value6}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,5)" :class="[{active: columns.length == 3}]" v-model="row.value6">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
              {{row.value6 == 1 ? '否' : row.value6 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[5]" :class="[{active: columns.length == 3}]" v-model="row.value6">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[5]" :class="[{active: columns.length == 3}]" v-model="row.value6">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value6}}
                  </span>
          </template>
          <template  #value7="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value7}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value7)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value7}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value7}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value7}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,6)" :class="[{active: columns.length == 3}]" v-model="row.value7">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
             {{row.value7 == 1 ? '否' : row.value7 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[6]" :class="[{active: columns.length == 3}]" v-model="row.value7">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[6]" :class="[{active: columns.length == 3}]" v-model="row.value7">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value7}}
                  </span>
          </template>
          <template  #value8="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value8}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value8)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value8}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value8}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value8}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,7)" :class="[{active: columns.length == 3}]" v-model="row.value8">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
             {{row.value8 == 1 ? '否' : row.value8 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[7]" :class="[{active: columns.length == 3}]" v-model="row.value8">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[7]" :class="[{active: columns.length == 3}]" v-model="row.value8">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value8}}
                  </span>
          </template>
          <template  #value9="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value9}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value9)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value9}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value9}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value9}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,8)" v-model="row.value9">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
             {{row.value9 == 1 ? '否' : row.value9 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[8]" :class="[{active: columns.length == 3}]" v-model="row.value9">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[8]" :class="[{active: columns.length == 3}]" v-model="row.value9">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value9}}
                  </span>
          </template>
          <template  #value10="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value10}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value10)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value10}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value10}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value10}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,9)" v-model="row.value10">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
             {{row.value10 == 1 ? '否' : row.value10 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[9]" :class="[{active: columns.length == 3}]" v-model="row.value10">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[9]" :class="[{active: columns.length == 3}]" v-model="row.value10">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value10}}
                  </span>
          </template>
          <template  #value11="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value11}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value11)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value11}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value11}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value11}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,10)" v-model="row.value11">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
             {{row.value11 == 1 ? '否' : row.value11 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[10]" :class="[{active: columns.length == 3}]" v-model="row.value11">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[10]" :class="[{active: columns.length == 3}]" v-model="row.value11">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value11}}
                  </span>
          </template>
          <template  #value12="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value12}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value12)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value12}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value12}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value12}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span  v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,11)" :class="[{active: columns.length == 3}]" v-model="row.value12">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
             {{row.value12 == 1 ? '否' : row.value12 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[11]" :class="[{active: columns.length == 3}]" v-model="row.value12">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[11]" :class="[{active: columns.length == 3}]" v-model="row.value12">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value12}}
                  </span>
          </template>
          <template  #value13="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value13}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value13)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value13}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value13}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value13}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span  v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,12)" :class="[{active: columns.length == 3}]" v-model="row.value13">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
             {{row.value13 == 1 ? '否' : row.value13 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[12]" :class="[{active: columns.length == 3}]" v-model="row.value13">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[12]" :class="[{active: columns.length == 3}]" v-model="row.value13">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value13}}
                  </span>
          </template>
          <template  #value14="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value14}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value14)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value14}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value14}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value14}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span  v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,13)" :class="[{active: columns.length == 3}]" v-model="row.value14">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
             {{row.value14 == 1 ? '否' : row.value14 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[13]" :class="[{active: columns.length == 3}]" v-model="row.value14">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[13]" :class="[{active: columns.length == 3}]" v-model="row.value14">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value14}}
                  </span>
          </template>
          <template  #value15="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value15}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value15)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value15}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value15}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value15}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span  v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,14)"  :class="[{active: columns.length == 3}]" v-model="row.value15">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
             {{row.value15 == 1 ? '否' : row.value15 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[14]" :class="[{active: columns.length == 3}]" v-model="row.value15">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[14]" :class="[{active: columns.length == 3}]" v-model="row.value15">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value15}}
                  </span>
          </template>
          <template  #value16="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value16}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value16)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value16}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value16}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value16}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span  v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,15)" :class="[{active: columns.length == 3}]" v-model="row.value16">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
             {{row.value16 == 1 ? '否' : row.value16 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[15]" :class="[{active: columns.length == 3}]" v-model="row.value16">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[15]" :class="[{active: columns.length == 3}]" v-model="row.value16">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value16}}
                  </span>
          </template>
          <template  #value17="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value17}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value17)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value17}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value17}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value17}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span  v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,16)" :class="[{active: columns.length == 3}]" v-model="row.value17">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
             {{row.value17 == 1 ? '否' : row.value17 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[16]" :class="[{active: columns.length == 3}]" v-model="row.value17">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[16]" :class="[{active: columns.length == 3}]" v-model="row.value17">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value17}}
                  </span>
          </template>
          <template  #value18="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value18}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value18)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value18}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value18}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value18}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span  v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,17)" :class="[{active: columns.length == 3}]" v-model="row.value18">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
             {{row.value18 == 1 ? '否' : row.value18 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[17]" :class="[{active: columns.length == 3}]" v-model="row.value18">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[17]" :class="[{active: columns.length == 3}]" v-model="row.value18">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value18}}
                  </span>
          </template>
          <template  #value19="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value19}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value19)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value19}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value19}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value19}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span  v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,18)" :class="[{active: columns.length == 3}]" v-model="row.value19">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
             {{row.value19 == 1 ? '否' : row.value19 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[18]" :class="[{active: columns.length == 3}]" v-model="row.value19">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[18]" :class="[{active: columns.length == 3}]" v-model="row.value19">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value19}}
                  </span>
          </template>
          <template  #value20="{ row }">
                  <span v-if="row.field == '申请号'">
                      {{row.value20}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '商标图形'" >
                               <div class="imgBox">
                      <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(row.value20)" alt="">
                    </div>
                  </span>
            <span v-else-if="row.field == '拼音'" >
                    {{row.value20}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（中文）'" >
                    {{row.value20}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span v-else-if="row.field == '与申请标近似部分（英文）'" >
                    {{row.value20}} <i style="color: #067BE3;font-size: 16px" class="el-icon-edit-outline"></i>
                  </span>
            <span  v-else-if="row.field == '采取措施'" >
                    <el-radio-group @change="e => modifyChange(e,19)" :class="[{active: columns.length == 3}]" v-model="row.value20">
                      <el-radio :label="1">提起无效</el-radio>
                      <el-radio :label="2">提起异议</el-radio>
                      <el-radio :label="3">提起撤三</el-radio>
                      <el-radio :label="4">沟通转让</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '是否满三年'" >
             {{row.value20 == 1 ? '否' : row.value20 == 2 ? '是' : ''}}
                  </span>
            <span v-else-if="row.field == '商标名称近似性'" >
                    <el-radio-group :disabled="disabledArr[19]" :class="[{active: columns.length == 3}]" v-model="row.value20">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else-if="row.field == '商品服务近似性'" >
                    <el-radio-group :disabled="disabledArr[19]" :class="[{active: columns.length == 3}]" v-model="row.value20">
                      <el-radio :label="true">论述</el-radio>
                      <el-radio :label="false">不论述</el-radio>
                    </el-radio-group>
                  </span>
            <span v-else>
                    {{row.value20}}
                  </span>
          </template>
        </vxe-grid>
      </div>

      <div style="height: 740px;" class="box">
        <div class="titles">附加信息</div>
        <div class="line"/>
        <div style="padding-left: 4px">
          <div class="significantParts">
            <p class="title">显著部分</p>
            <div :style="{height: applyData.salientPartFlag ? '100px' : '50px'}" class="significantParts_item">
              <div style="display: flex;align-items: center">
                <p style="margin-right: 56px" class="item">申请商标是否是由引证商标与缺乏显著性部分组成:</p>
                <el-radio-group class="radio-group" v-model="applyData.salientPartFlag">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </div>
               <br/>
              <el-input v-if="applyData.salientPartFlag" style="width: 727px;top: -20px" v-model="applyData.salientPartMessage"
                        placeholder="请填入缺乏显著性部分内容"></el-input>
            </div>
            <p style="margin-top: 18px" class="title">在先权利</p>
            <div class="significantParts_item_">
              <span class="item">申请人有在先已注册的与申请商标相同近似的商标:</span>
              <el-radio-group class="radio-group" @change="previousSimilarEvent" v-model="applyData.previousSimilarBlag">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </div>
            <el-table
              v-loading="loadingSimilar"
              v-if="applyData.previousSimilarBlag"
              class="table_"
              height="350"
              header-row-class-name="table_header"
              ref="multipleTable"
              :data="powerFirstTable"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange">
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                label="商标图案"
                width="100">
                <template slot-scope="scope">
                  <div class="imgBox">
                    <img style="max-width: 100%;max-height: 46px" :src="queryDataUrl(scope.row.imageUrl)" alt="">
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="sbmc"
                label="商标名称"
                width="100">
              </el-table-column>
              <el-table-column
                prop="intcls"
                label="类别"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="appno"
                label="申请号"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                width="100"
                prop="tmapplicant"
                label="申请人名称"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="tmaddress"
                width="100"
                label="申请人地址"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="appdate"
                label="申请日"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="tmstatus"
                label="商标状态"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="similargroup"
                label="类似群组"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="tmagent"
                label="代理机构"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="regno"
                label="注册号"
                show-overflow-tooltip>
              </el-table-column>
            </el-table>
            <div style="display: flex;justify-content: right;margin-top: 10px">
              <el-pagination
                v-if="applyData.previousSimilarBlag"
                @current-change="similarCurrentChange"
                :current-page.sync="similarCurrent"
                :page-size="similarSize"
                layout="total, prev, pager, next"
                :total="similarTotal">
              </el-pagination>
            </div>
          </div>

        </div>
      </div>

      <div style="margin-top: 20px;display: flex;justify-content: center;margin-bottom: 80px">
        <div :style="{width: changeFlag ? '200px' : '120px'}" class="generateClass" @click="generateReviewSave('2')">{{changeFlag ? '确认信息并生成' : '开始生成'}}</div>
      </div>
    </div>

  </div>
</template>

<script>
import PDFJS from "pdfjs-dist";
import pdf from 'vue-pdf'
import TinyEditor from "@/components/trademarkRejectionAi/TinyEditor"
import ProgressLoading from "@/components/trademarkRejectionAi/ProgressLoading";
import hljsCss from "mavon-editor/src/lib/core/hljs/lang.hljs.css";
const axios = require('axios');
axios.defaults.timeout = 60 * 1000 * 5;
export default {
  name: "trademarkRejection",
  components: { pdf, TinyEditor, ProgressLoading },

  data:()=>{
    return {
      disabledArr: [],
      requestTokens: [],
      citationRightsData: [],
      saveFlag: false,
      revokedData:[],
      similarTrademarkData: [],
      // 引证商标连续三年
      commonSubstring: {
        flag: false,
        word: ''
      },
      changeFlag: false,
      flagHan: true,
      flagMu: true,
      selectedRows: [], // 全局选中的行ID
      loadingSimilar: false,
      similarSize: 4,
      similarCurrent: 1,
      similarTotal: 0,
      totalMessages: [],
      powerFirstTable: [],
      multipleSelection: [],
      field:[{title: '申请号', field: 'applyNo'}, {title: '商标名称', field: 'trademarkName'}, {title: '商标图形', 'field': 'trademarkImageUrl'}, {title: '拼音', 'field': 'chinesePinYin'}, {title: '与申请标近似部分（中文）', field: 'similarPartChinese'}, , {title: '与申请标近似部分（英文）', field: 'similarPartEnglish'}, {title: '商标类别', field: 'niceCode'}, {title: '注册日期', field: 'applyDate'}, {title: '是否满三年', field: 'trademarkThreeYear'}, {title: '采取措施', field: 'takeActionType'},
        {title: '商标名称近似性', field: 'trademarkSimilarDiscussionFlag'}, {title: '商品服务近似性', field: 'serviceSimilarDiscussionFlag'}],
      columns: [
        { field: 'field', title: '', width: 120, fixed: 'left' },
        { field: 'value', title: '申请商标', minWidth: 329, fixed: 'left' },
      ],
      gridOptions1: [],
      ifShowEnd:true,
      panduan: 0,
      chatLoading: false,
      flagw: false,
      stopSignal: null,  // 保存取消令牌
      stopSignal2: null, //记录执行步骤
      pickerOptions: {
        disabledDate(time) {
          // 获取今天的日期
          const today = new Date();
          // 如果选择的日期在今天之后，则禁用
          return time.getTime() > today.getTime();
        },
      },
      disabledFlag: 0,
      loading2: false,
      cuchunApplyNoFlag: '',
      applyNoFlag: false,
      checkList: [],
      radio: 1,
      applyData: {},
      applyDatas: [],
      loading1: false,
      loading3: false,
      clickData: '',
      fileData: '',
      activeName: 'first',
      index: 0,
      //是否是驳回转达
      ifConvey: '',

      fullDetail: '',
      tableData: [],
      loadingTable: false,
      historyFlag: true,
      ifHistoryBackMessage:false,
      historyBackMessage:null,

      loading: false,
      loadingText:"拼命加载中",

      ifShowConfig: true,

      //商标驳回采用上传生成还是输入生成 0-上传生成 1-输入生成
      trademarkTitleActive:0,
      disableUpload:false,
      ocrFileName:"",
      //商标驳回输入生成配置项
      applyTrademarkNo:null,
      referenceTrademarkNo:null,
      finalRejection:null,
      //商标驳回-商标类型
      trademarkType:null,
      // 驳回生成chatNumber 调用接口获取
      fileChatNumber:"",

      //驳回历史列表
      trademarkHistoryList:[],
      trademarkHistoryQueryParam:{
        pageNum:1,
        pageSize:10
      },
      trademarkHistoryTotal:0,

      //复审历史列表
      reviewHistoryList:[],
      reviewHistoryQueryParam:{
        pageNum:1,
        pageSize:10
      },
      reviewHistoryTotal:0,

      ifGenerateFromFile:true,
      buttonDisabled:true,
      ifShowLeftBlock:true,
      ifShowRightBlock:true,
      leftBlockSpan:8,
      middleBlockSpan:8,
      rightBlockSpan:8,

      //文件预览
      fileUrl:"",
      numPages:1,
      ifFullScreenShowFile:false,
      replySourceFileUrl:"",
      pdfUint8Array:null,

      timer:null,
      //后台返回数据解析而成的html
      htmlData:"",
      //虚假进度
      fakeHtmlProgressNumber:0,
      //是否展示虚假html内容
      fakeHtml:true,
      //虚假html内容
      fakeHtmlData:"",
      //导出文件文件信息
      ganerateFileData:null,
      generateDomId2Item:new Map(),
      generateDomId2MessageId:new Map(),
      //导出文件中换行信息列表，后台返回的列表中出现的换行换页符按顺序插入此列表
      generateBrList:[],
      //导出文件向后台传输的列表
      generateDownloadList:[],

      //对话chatNmber
      chatNumber:"",

      dialogList:[{
        type:"assistant",
        conversationId:0,
        content:"欢迎使用蓝灯鱼驳通智写AI对话"
      }],
      myAIDialogInput:"",
      markdownOption: { //自定义文本标签
        bold: true, // 粗体
        italic: true,// 斜体
        strikethrough: true, // 中划线
        underline: true, // 下划线
      },
      externalLink: {
        markdown_css: function () {
          return '';
        },
        hljs_js: function () {
          return '';
        },
        hljs_lang: function (lang) {
          return '';
        },
        hljs_css: function (css) {
          if (hljsCss[css]) {
            return '';
          }
          return '';
        },
        katex_js: function () {
          return '';
        },
        katex_css: function () {
          return '';
        }
      },
      editorConfig: {
        toolbar: {
          githubLink: false, // 禁用 GitHub 链接
        },
      },
    }
  },
  watch: {
    gridOptions1:{
      handler(newValue, oldValue) {
        this.changeFlag = true
      },
      deep: true  // 设置 deep 为 true，表示深度监视
    },
    applyData:{
      handler(newValue, oldValue) {
        this.changeFlag = true
      },
      deep: true  // 设置 deep 为 true，表示深度监视
    },
    applyNoFlag(newVal, oldVal) {
      if(!newVal) {
        if(this.cuchunApplyNoFlag != this.applyData.applyNo){
          this.loading2 = true
          this.cuchunApplyNoFlag = this.applyData.applyNo
          this.queryTramkes(this.applyData)
        }
      }
    },
    trademarkTitleActive(newVal, oldVal) {
      if(this.trademarkTitleActive == 1){
        this.handleRetractButton('left1')
      }else {
        this.handleRetractButton('left2')
      }
    }
  },
  async mounted() {
    // this.queryHistory()
    // this.queryHistory_()
    // this.renderPdf()
    // this.similar()
    // this.save()
    document.body.addEventListener("click", () => {
      this.applyNoFlag = false
      this.flagHan = true
      this.flagMu = true
    });
    this.assembly()
  },
  beforeDestroy() {
    document.body.removeEventListener("click", () => {
      this.applyNoFlag = false
      this.flagHan = true
      this.flagMu = true
    });
  },
  methods: {
    delMessageId(){
      this.$axios.post('/trademark/reject/chat/message/batch/delete/param', {
        "fileChatNumber": this.fullDetail.fileChatNumber,
        "chatType": 6
      }).then(({data}) => {
      }).catch(err => {
      })
    },
    modifyChange(e,val) {
      if(this.applyData.quoteTrademarkRejectList[val].trademarkStatus != "无效") {
        this.$set(this.disabledArr, val , (e == 1 || e == 2) ? true : false)
      }

      if(e == 1 || e == 2) {
        this.gridOptions1 = this.gridOptions1.map((item, index) => {
          //商标名称近似性
          if (item.field == '商标名称近似性') {
            item['value' + (val + 1)] = false
          }
          //商品服务近似性
          if (item.field == '商品服务近似性') {
            item['value' + (val + 1)] = false
          }

          return item;
        })
        this.trademarkCategoryChange()
      }
    },
    trademarkCategoryChange() {

      this.gridOptions1.map((item, index) => {

        //申请号
        if (item.field == '申请号') {
          this.applyData.applyNo = item.value
          this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((items, indexs) => {
            items.applyNo = item['value' + (indexs + 1)]
            return items
          })
        }
        //拼音
        if (item.field == '拼音') {
          this.applyData.chinesePinYin = item.value
          this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((items, indexs) => {
            items.chinesePinYin = item['value' + (indexs + 1)]
            return items
          })
        }
        //拼音
        if (item.field == '与申请标近似部分（中文）') {
          this.applyData.similarPartChinese = item.value
          this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((items, indexs) => {
            items.similarPartChinese = item['value' + (indexs + 1)]
            return items
          })
        }
        if (item.field == '与申请标近似部分（英文）') {
          this.applyData.similarPartEnglish = item.value
          this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((items, indexs) => {
            items.similarPartEnglish = item['value' + (indexs + 1)]
            return items
          })
        }
        //采取措施
        if (item.field == '采取措施') {
          this.applyData.takeActionType = item.value
          this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((items, indexs) => {
            items.takeActionType = item['value' + (indexs + 1)]
            return items
          })
        }

        //商标名称近似性
        if (item.field == '商标名称近似性') {
          this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((items, indexs) => {
            items.trademarkSimilarDiscussionFlag = item['value' + (indexs + 1)]
            return items
          })
        }
        //商品服务近似性
        if (item.field == '商品服务近似性') {
          this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((items, indexs) => {
            items.serviceSimilarDiscussionFlag = item['value' + (indexs + 1)]
            return items
          })
        }
      })
      let objs = null
      this.gridOptions1 = []
      this.field.forEach((item, index) => {
        if(this.applyData.trademarkCategory == 1) {
          if(item.title == '商标图形') {
            return;
          }
        }
        if(this.applyData.trademarkCategory == 2) {
          if(item.title == '商标名称') {
            return;
          }
        }

        if(this.applyData.trademarkNameCategory == 1) {
          if(item.title == '与申请标近似部分（英文）') {
            return;
          }
        }

        if(this.applyData.trademarkNameCategory == 2) {
          if(item.title == '与申请标近似部分（中文）') {
            return;
          }
          if(item.title == '拼音') {
            return;
          }
        }
        if(this.applyData.trademarkCategory == 2) {
          if(item.title == '与申请标近似部分（英文）') {
            return;
          }
          if(item.title == '与申请标近似部分（中文）') {
            return;
          }
          if(item.title == '拼音') {
            return;
          }
        }
        objs = {}
        //第一列值
        objs.field = item.title
        this.columns.map((items, indexs) => {
          if (indexs == 1) {
            // 第二列值
            objs.value = this.applyData[item.field]

          } else if (indexs > 1) {
            objs['value' + (indexs - 1)] = this.applyData.quoteTrademarkRejectList[indexs - 2][item.field]
          }
        })

        this.gridOptions1.push({
          ...objs
        })
      })
    },
    assembly() {
      let data = JSON.parse(localStorage.getItem('assembly'))

      let obj = null
      this.panduan = this.trademarkTitleActive
      this.historyFlag = false
      this.fullDetail = data.data
      if (typeof data.data.trademarkAssemblyInfo === "object" && data.data.trademarkAssemblyInfo !== null) {
        obj = data.data.trademarkAssemblyInfo
      } else if (typeof data.data.trademarkAssemblyInfo === "string") {
        obj = JSON.parse(data.data.trademarkAssemblyInfo)
      } else {
        console.log("既不是对象也不是字符串");
      }
      if (typeof data.data.rejectAssemblyInfo === "object" && data.data.rejectAssemblyInfo !== null) {
        obj.rejectAssemblyInfo = data.data.rejectAssemblyInfo
      } else if (typeof data.data.rejectAssemblyInfo === "string") {
        obj.rejectAssemblyInfo = JSON.parse(data.data.rejectAssemblyInfo)
      } else {
        console.log("既不是对象也不是字符串");
      }

      this.applyData = obj
      this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map(item => {
        if(item.trademarkStatus == '无效' || item.takeActionType == 1 || item.takeActionType == 2) {
          item.disabled = true
        }
        return item;
      })
      this.disabledArr = this.applyData.quoteTrademarkRejectList.map(item => item.disabled)
      if(this.applyData.previousSimilarBlag && this.applyData.previousSimilarRejectList.length > 0) {
        this.powerFirstTable = this.applyData.previousSimilarRejectList
      }
      this.$nextTick(() => {
        this.$refs.multipleTable.doLayout()
      })
      this.applyDatas = [{...obj}, ...obj.quoteTrademarkRejectList].map((item, index) => {
        if (index == 0) {
          item.name = '申请商标'
        } else {
          item.name = '引证商标' + (this.applyData.quoteTrademarkRejectList.length == 1 ? '' : index)
        }
        return item
      })
      this.cuchunApplyNoFlag = obj.applyNo
      this.columns = []
      this.columns = [
        {field: 'field', title: '', width: 130, fixed: 'left', showOverflow: false},
        //
        {
          field: 'value', title: '申请商标', editRender: {name: 'input'}, slots: {
            // 使用插槽模板渲染
            default: 'value',
          }, fixed: 'left', minWidth: 329, showOverflow: false
        },
      ]
      this.gridOptions1 = []
      obj.quoteTrademarkRejectList.map((item, index) => {
        // item.debateDimension = []
        this.columns.push({
          field: 'value' + (index + 1), title: '引证商标' + (this.applyDatas.length == 2 ? '' : index + 1), editRender: {name: 'input'}, slots: {
            // 使用插槽模板渲染
            default: 'value' + (index + 1),
          }, minWidth: 329, showOverflow: false
        })
        return item
      })
      let objs = null
      this.field.forEach((item, index) => {
        if(this.applyData.trademarkCategory == 1) {
          if(item.title == '商标图形') {
            return;
          }
        }
        if(this.applyData.trademarkCategory == 2) {
          if(item.title == '商标名称') {
            return;
          }
        }

        if(this.applyData.trademarkNameCategory == 1) {
          if(item.title == '与申请标近似部分（英文）') {
            return;
          }
        }

        if(this.applyData.trademarkNameCategory == 2) {
          if(item.title == '与申请标近似部分（中文）') {
            return;
          }
          if(item.title == '拼音') {
            return;
          }
        }

        if(this.applyData.trademarkCategory == 2) {
          if(item.title == '与申请标近似部分（英文）') {
            return;
          }
          if(item.title == '与申请标近似部分（中文）') {
            return;
          }
          if(item.title == '拼音') {
            return;
          }
        }
        objs = {}
        //第一列值
        objs.field = item.title
        this.columns.map((items, indexs) => {
          if (indexs == 1) {
            // 第二列值
            objs.value = obj[item.field]

          } else if (indexs > 1) {
            objs['value' + (indexs - 1)] = obj.quoteTrademarkRejectList[indexs - 2][item.field]
          }
        })

        this.gridOptions1.push({
          ...objs
        })
      })

      this.applyData.salientPartFlag = false
      this.applyData.previousSimilarBlag = false
      setTimeout(() => {
        this.changeFlag = false
      },1000)
      if(this.$route.query.backReview){
        this.$router.push({
          path: 'trademarkRejectionAI',
          query: {
            backReview: 1
          }
        })
      }
    },
    headerCellClassName({ $rowIndex, column, columnIndex, $columnIndex }){
      if(columnIndex == 0 || columnIndex == 1) {
        return 'fixedHeader'
      }else {
        return 'moveHeader'
      }
    },
    //1.7修改新驳回复审对话消息表数据--ai回答问题的信息保存
    informationAiSave(item) {
      this.$axios.post("/trademark/reject/chat/message/update", {
        id: item.id,
        response: item.as
      })
    },
    contentChange(e, item,) {
      // if (this.saveFlag) {
      //   this.informationAiSave(item)
      // }
      this.informationAiSave(item)
    },
    //判断首字母不同
    areFirstLettersSame(word1, word2) {
      // 获取首字母并转换为小写，确保不受大小写影响
      const firstLetter1 = word1.charAt(0).toLowerCase();
      const firstLetter2 = word2.charAt(0).toLowerCase();

      // 判断首字母是否相同
      return firstLetter1 === firstLetter2;
    },
    //个别字母不同
    areWordsDifferByOneLetter(word1, word2) {
      // 如果两个单词的长度不同，直接返回 false
      if (word1.length !== word2.length) {
        return false;
      }

      let differenceCount = 0;

      // 比较两个单词中的每个字母
      for (let i = 0; i < word1.length; i++) {
        if (word1[i] !== word2[i]) {
          differenceCount++;
        }
      }

      // 如果只存在一个字母不同，则返回 true，否则返回 false
      return differenceCount === 1;
    },
    separateLanguage(input) {
      let chinese = '';
      let english = '';

      for (let i = 0; i < input.length; i++) {
        const char = input.charAt(i);

        if (/[\u4e00-\u9fa5]/.test(char)) {
          // 判断是否为中文字符（Unicode 范围）
          chinese += char;
        } else if (/^[a-zA-Z]$/.test(char)) {
          // 判断是否为英文字符
          english += char;
        }
      }

      return {chinese, english};
    },
    //判断英文单词首字母是否相同
    isSameFirstLetter(word1, word2) {
      // 转为小写后，比较首字母
      return word1.charAt(0).toLowerCase() === word2.charAt(0).toLowerCase();
    },

    areAnagrams(word1, word2) {
      // 如果两个单词长度不同，直接返回 false
      if (word1.length !== word2.length) return false;

      // 将两个单词的字母排序并比较
      const sortedWord1 = word1.split('').sort().join('');
      const sortedWord2 = word2.split('').sort().join('');

      // 判断是否由相同的字母组成，且顺序不同
      return sortedWord1 === sortedWord2 && word1 !== word2;
    },
    //判断英文单词是否由词组构成
    isPhrase(name) {
      // 检查是否包含空格（多个单词）
      if (!name.includes(" ")) {
        return false; // 单个单词，不是词组
      }

      // 拆分单词
      const words = name.split(" ");

      // 检查每个单词是否符合规则（首字母大写，后续小写）
      const wordPattern = /^[A-Z][a-z]*$/;
      for (let word of words) {
        if (!wordPattern.test(word)) {
          return false; // 如果任一单词不符合规则，则不是词组
        }
      }

      // 如果通过所有规则，认为是词组
      return true;
    },
    //汉字标
    chineseCharacterMark(obj) {
      let arr = []
      this.applyDatas.map((item, index) => {
        if (index == 0) {
          // obj = item
        } else {
          if(this.applyData.trademarkNameCategory == 3 && !item.similarPartChinese){

          }else {
            if(item.trademarkStatus != '无效' && item.takeActionType != 1 && item.takeActionType != 2 && item.trademarkSimilarDiscussionFlag ) {
              //判断申请商标与引证商标读音是否相同
              if (obj.chinesePinYin && item.chinesePinYin && (obj.chinesePinYin == item.chinesePinYin)) {
                //文字构成
                arr.push({
                  content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的文字构成明显区别`,
                  applyNo: item.applyNo,
                  questionType: 61,
                  as: ''
                })
                //含义
                arr.push({
                  content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的含义明显不同`,
                  applyNo: item.applyNo,
                  questionType: 41,
                  as: ''
                })
                //字形区别
                arr.push({
                  content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的字形区别明显`,
                  applyNo: item.applyNo,
                  questionType: 51,
                  as: ''
                })
              } else {
                //由引证商标+缺乏显著部分组成
                if (this.applyData.salientPartFlag) {
                  //显著部分
                  arr.push({
                    content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的呼叫发音区别明显`,
                    applyNo: item.applyNo,
                    questionType: 31,
                    as: ''
                  })
                  //显著部分
                  arr.push({
                    content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的含义明显不同`,
                    applyNo: item.applyNo,
                    questionType: 41,
                    as: ''
                  })
                  //显著部分
                  arr.push({
                    content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”有区别的汉字具有显著性`,
                    applyNo: item.applyNo,
                    questionType: 22,
                    as: ''
                  })
                } else {
                  if (obj.trademarkNameChineseNumber == 1) {
                    //显著部分
                    arr.push({
                      content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的字形区别明显`,
                      applyNo: item.applyNo,
                      questionType: 51,
                      as: ''
                    })
                    //显著部分
                    arr.push({
                      content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的整体外观区别明显`,
                      applyNo: item.applyNo,
                      questionType: 12,
                      as: ''
                    })
                  } else if (obj.trademarkNameChineseNumber == 2) {
                    //显著部分
                    arr.push({
                      content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的呼叫发音区别明显`,
                      applyNo: item.applyNo,
                      questionType: 31,
                      as: ''
                    })
                    //显著部分
                    arr.push({
                      content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的含义明显不同`,
                      applyNo: item.applyNo,
                      questionType: 41,
                      as: ''
                    })
                  } else if (obj.trademarkNameChineseNumber == 3) {
                    //显著部分
                    arr.push({
                      content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的呼叫发音区别明显`,
                      applyNo: item.applyNo,
                      questionType: 31,
                      as: ''
                    })
                    //显著部分
                    arr.push({
                      content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的含义明显不同`,
                      applyNo: item.applyNo,
                      questionType: 41,
                      as: ''
                    })
                    //显著部分
                    arr.push({
                      content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的文字构成明显区别`,
                      applyNo: item.applyNo,
                      questionType: 61,
                      as: ''
                    })
                  } else {
                    //显著部分
                    arr.push({
                      content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的呼叫发音区别明显`,
                      applyNo: item.applyNo,
                      questionType: 31,
                      as: ''
                    })
                    //显著部分
                    arr.push({
                      content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的含义明显不同`,
                      applyNo: item.applyNo,
                      questionType: 41,
                      as: ''
                    })
                  }
                }

              }
            }
          }

        }
      })
      return arr
    },
    //英文标
    englishStandard(obj) {
      let arr = []
      let flag = null
      let data = null
      this.applyDatas.map(async (item, index) => {
        if (index == 0) {
          data = await this.$axios.post("/aigc/app/api/translate", {
            prompt: item.trademarkName
          })
          flag = data.data.data.includes('\n') ? data.data.data.split('\n')[1] : data.data.data
          console.log(data.data.data.includes('\n')
            ? data.data.data.split('\n')[1]
            : data.data.data, "翻译结果")
        } else {
          if(this.applyData.trademarkNameCategory == 3 && !item.similarPartEnglish){

          }else {
            if(item.trademarkName.indexOf(obj.trademarkName) != -1) { //判断申请商标是否被引证商标完全顺序包含
              if(item.trademarkStatus != '无效' && item.takeActionType != 1 && item.takeActionType != 2 && item.trademarkSimilarDiscussionFlag) {
                //含义
                arr.push({
                  content: `申请商标“${obj.trademarkName}”与引证商标${index}“${item.trademarkName}”的呼叫发音区别明显`,
                  applyNo: item.applyNo,
                  questionType: 32,
                  as: ''
                })
                //呼叫发音
                arr.push({
                  content: `申请商标“${obj.trademarkName}”与引证商标${index}“${item.trademarkName}”的含义明显不同`,
                  applyNo: item.applyNo,
                  questionType: 42,
                  as: ''
                })
              }
            }else if (this.applyData.englishNameCategory == 1) {
              //判断申请商标是由词组组成 由多个词组构成，某个词组与引证商标相同
              if(item.trademarkStatus != '无效' && item.takeActionType != 1 && item.takeActionType != 2 && item.trademarkSimilarDiscussionFlag) {
                //含义
                arr.push({
                  content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的呼叫发音区别明显`,
                  applyNo: item.applyNo,
                  questionType: 32,
                  as: ''
                })
                //呼叫发音
                arr.push({
                  content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的含义明显不同`,
                  applyNo: item.applyNo,
                  questionType: 42,
                  as: ''
                })
              }
            }else {
              if(item.trademarkStatus != '无效' && item.takeActionType != 1 && item.takeActionType != 2 && item.trademarkSimilarDiscussionFlag) {
                if (item.trademarkNameCategory == 1 && flag == this.applyDatas.trademarkName) {
                  //首字母不同
                  arr.push({
                    content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的首字母明显不同`,
                    applyNo: item.applyNo,
                    questionType: 101,
                    as: ''
                  })
                  //呼叫发音
                  arr.push({
                    content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的呼叫发音区别明显`,
                    applyNo: item.applyNo,
                    questionType: 32,
                    as: ''
                  })
                  //含义
                  arr.push({
                    content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的含义明显不同`,
                    applyNo: item.applyNo,
                    questionType: 42,
                    as: ''
                  })
                }else {
                  //判断申请商标字母数
                  //2个字母及以下
                  if (obj.englishLetterNumber <= 2) {
                    //外观
                    arr.push({
                      content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的字体区别明显`,
                      applyNo: item.applyNo,
                      questionType: 71,
                      as: ''
                    })
                    //显著部分
                    arr.push({
                      content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的整体外观区别明显`,
                      applyNo: item.applyNo,
                      questionType: 13,
                      as: ''
                    })
                  }
                  //三个字母及以上
                  if (obj.englishLetterNumber >= 3) {
                    //判断是不是四个字母及以上且首字母不同
                    if (obj.englishLetterNumber >= 4 && !this.areFirstLettersSame(obj.trademarkName, item.similarPartEnglish)) {
                      //首字母不同
                      arr.push({
                        content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的首字母明显不同`,
                        applyNo: item.applyNo,
                        questionType: 101,
                        as: ''
                      })
                      //呼叫发音
                      arr.push({
                        content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的呼叫发音区别明显`,
                        applyNo: item.applyNo,
                        questionType: 32,
                        as: ''
                      })
                      //含义
                      arr.push({
                        content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的含义明显不同`,
                        applyNo: item.applyNo,
                        questionType: 42,
                        as: ''
                      })
                    }else if (this.areAnagrams(obj.trademarkName, item.similarPartEnglish)) {
                      //判断字母是否相同,顺序不同
                      //外观
                      arr.push({
                        content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的字母顺序明显不同`,
                        applyNo: item.applyNo,
                        questionType: 81,
                        as: ''
                      })
                      arr.push({
                        content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的呼叫发音区别明显`,
                        applyNo: item.applyNo,
                        questionType: 32,
                        as: ''
                      })
                      arr.push({
                        content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的整体外观区别明显`,
                        applyNo: item.applyNo,
                        questionType: 13,
                        as: ''
                      })
                    } else {
                      //呼叫发音
                      arr.push({
                        content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的呼叫发音区别明显`,
                        applyNo: item.applyNo,
                        questionType: 32,
                        as: ''
                      })
                      //含义
                      arr.push({
                        content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的含义明显不同`,
                        applyNo: item.applyNo,
                        questionType: 42,
                        as: ''
                      })
                      //字母构成
                      arr.push({
                        content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的字母构成明显不同`,
                        applyNo: item.applyNo,
                        questionType: 91,
                        as: ''
                      })
                    }
                  }
                }
              }

            }
          }
        }
      })
      return arr
    },
    //组合标
    combinationLabel(obj) {
      let arr = []
      let objs = this.separateLanguage(obj.trademarkName)
      let arr1 = []
      let arr2 = []

      if (objs.chinese) {
        arr1 = this.chineseCharacterMark({...obj, trademarkName: objs.chinese}, [])
      }

      if (objs.english) {
        arr2 = this.englishStandard({...obj, trademarkName: objs.english}, [])
      }

      return arr1.concat(arr2)
    },
    //文字规则
    textRules(obj) {
      let arr = []
      //商标名称类型
      switch (this.applyData.trademarkNameCategory) {
        //汉字标
        case 1:
          arr = this.chineseCharacterMark(obj);
          break;
        //英文标
        case 2:
          arr = this.englishStandard(obj);
          break;
        //组合标
        case 3:
          arr = this.combinationLabel(obj);
          break;
      }
      return arr
    },
    //图形规则
    graphicsRules(obj) {
      let arr = []
      this.applyDatas.map((item, index) => {
        if (index == 0) {
          // obj = item
        } else {
          if(item.trademarkStatus != '无效' && item.takeActionType != 1 && item.takeActionType != 2 && item.trademarkSimilarDiscussionFlag) {
            //外观
            arr.push({
              content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的整体外观方面区别明显`,
              applyNo: item.applyNo,
              questionType: 11,
              as: ''
            })
            //显著部分
            // arr.push({
            //   content: `申请商标“${obj.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的显著部分区别明显`,
            //   applyNo: item.applyNo,
            //   questionType: 21,
            //   as: ''
            // })
          }
        }
      })

      return arr
    },
    //文字加图形
    textGraphics(obj) {
      let arr = []
      let arr1 = []
      let arr2 = []
      //文字标
      arr1 = this.textRules(this.applyData);
      //图形标
      arr2 = this.graphicsRules(this.applyData);
      return arr2.concat(arr1)
    },
    rule() {
      let arr = []
      //判断商标类型
      switch (this.applyData.trademarkCategory) {
        //文字标
        case 1:
          arr = this.textRules(this.applyData);
          break
        //图形标
        case 2:
          arr = this.graphicsRules(this.applyData);
          break
        //文字加图形
        case 3:
          arr = this.textGraphics(this.applyData);
          break
      }
      this.applyDatas.map((item, index) => {
        if (index == 0) {
          // obj = item
        } else {
          if(item.trademarkStatus != '无效' && item.takeActionType != 1 && item.takeActionType != 2 && item.serviceSimilarDiscussionFlag) {
            //外观
            arr.push({
              content: `申请商标“${this.applyData.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的指定商品功能、用途明显区别`,
              applyNo: item.applyNo,
              questionType: 211,
              as: ''
            })
            //显著部分
            arr.push({
              content: `申请商标“${this.applyData.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的指定商品生产部门明显区别`,
              applyNo: item.applyNo,
              questionType: 221,
              as: ''
            })
            //外观
            arr.push({
              content: `申请商标“${this.applyData.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的指定商品销售渠道明显区别`,
              applyNo: item.applyNo,
              questionType: 231,
              as: ''
            })
            //显著部分
            arr.push({
              content: `申请商标“${this.applyData.trademarkName}”与引证商标${this.applyDatas.length == 2 ? '' : index}“${item.trademarkName}”的指定商品消费对象明显区别`,
              applyNo: item.applyNo,
              questionType: 241,
              as: ''
            })
          }
        }
      })

      // this.applyDatas.map((item,index) => {
      //   if(index == 0) {
      //     // obj = item
      //   }else {
      //     this.uniqueArray(arr.filter(item => item.content.indexOf(`与引证商标${index}`)))
      //   }
      // })

      return arr

    },
    uniqueArray(array) {
      // 方法1：使用 Map 去重
      return Array.from(
        new Map(array.map(item => [item.questionType, item])).values()
      );
    },
    //重新生成
    regenerateB() {
      this.ifShowEnd = true
      if (this.ifConvey) {
        this.refutationArticleGenerate(true)
      } else {

        this.totalMessages = this.totalMessages.map(item => {
          item.as = ''
          return item
        })
        this.save()
        // this.replyArticleGenerate(true)
      }
    },
    generateNow(type) {
      this.ifHistoryBackMessage = false
      this.historyBackMessage = null
      switch (type) {
        case "1":
          this.refutationArticleGenerate(false)
          break
        case "2":
          this.replyArticleGenerate(false)
          break
      }
    },
    handleStopStep() {
      // 在需要取消请求时调用 cancel() 方法
      if (this.stopSignal) {
        this.stopSignal.cancel("请求已被取消");
      }

      this.requestTokens.forEach(token => {
        if (typeof token.cancel === "function") {
          this.flagw = false
          token.cancel("请求已被用户取消");
        }
      });
      // 清空已取消的 token
      this.requestTokens = [];
    },
    handleStopSteps() {
      // 在需要取消请求时调用 cancel() 方法
      if (this.stopSignal2) {
        this.stopSignal2.cancel("请求已被取消");
      }
    },
    zhongjian5() {
      if (this.ifConvey) {
        this.handleSendMessage()
      } else {
        this.handleSendMessages()
      }
    },
    cellClassName({ $rowIndex, column, columnIndex, $columnIndex}) {
      if($columnIndex == 0 || $columnIndex == 1 ) {
        return 'cellClassNameFixed'
      }else {
        return 'cellClassNameMove'
      }

    },
    editClosed({row, rowIndex, $rowIndex, column, columnIndex, $columnIndex}) {
      if (this.clickData.applyNo != row.applyNo) {
        if (this.ifConvey) {
          this.loading1 = true
        } else {
          this.loading3 = true
        }

        this.queryTramke(row)
      }
    },
    editActivated({row, rowIndex, $rowIndex, column, columnIndex, $columnIndex}) {
      this.clickData = JSON.parse(JSON.stringify(row))
    },
    editCloseds({row, rowIndex, $rowIndex, column, columnIndex, $columnIndex}) {
      if (row.field == "申请号" && JSON.stringify(row) != JSON.stringify(this.clickData)) {
        this.loading3 = true
        this.queryTramke(row, columnIndex - 1)
      }
      // if(this.clickData.field != row.applyNo) {
      //   if(this.ifConvey){
      //     this.loading1 = true
      //   }
      //   else {
      //     this.loading3 = true
      //   }
      //
      // }
    },
    editActivateds({row, rowIndex, $rowIndex, column, columnIndex, $columnIndex}) {
      if (row.field == "申请号") {
        this.clickData = JSON.parse(JSON.stringify(row))
      }
    },
    handleRemove(file, fileList) {
      this.fileData = ''
    },
    jump(item) {
      this.index = item
      if (item == 1) {
        this.$router.push('/trademarkObjection')
      }
    },
    isFistoryFlagFullDetail() {
      if (this.historyFlag) {
        return 16
      } else if (this.fullDetail) {
        return 12
      } else {
        return 24
      }
    },
    open() {
      this.historyFlag = !this.historyFlag
    },
    queryDataUrl(data) {
      if (data && data.includes("data:image")) return data;
      else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
      else return "";
    },
    queryTramkes(row) {
      this.$axios.post('/trademark/file/parse/record/trademark', {
        "applyNo": row.applyNo,
        "niceCode": row.niceCode
      }).then(({data}) => {
        if (data.code == 0) {
          let rowIndex = this.applyData.rowIndex
          this.applyData = {...data.data, rowIndex: rowIndex}
        } else {
          this.$message(data.msg)
        }
        this.loadingTable = false
        this.loading3 = false
        this.loading2 = false
        this.loading1 = false
      }).catch(err => {
        this.loading3 = false
        this.loading2 = false
        this.loading1 = false
      })
    },
    queryTramke(row, index) {
      this.$axios.post('/trademark/file/parse/record/trademark', {
        "applyNo": this.ifConvey ? row.applyNo : index == 0 ? row.value : row['value' + index],
        "niceCode": this.ifConvey ? row.niceCode : this.applyDatas[index].niceCode
      }).then(({data}) => {
        if (data.code == 0) {
          if (this.ifConvey == true) {
            this.tableData = JSON.parse(JSON.stringify(this.tableData.map(item => item.applyNo === data.data.applyNo ? {
              ...data.data,
              name: item.name,
              rowIndex: item.rowIndex
            } : item)));
          } else {
            if (index == 0) {
              this.applyData = {
                ...this.applyData,
                ...data.data,
                englishNameCategory: '',
                englishLetterNumber: '',
                trademarkNameChineseNumber: '',
                trademarkNameCategory: '',
                chinesePinYin: '',
                similarPartChinese: '',
                similarPartEnglish: '',
                trademarkThreeYear: '',
                takeActionType: ''
              }
            } else {
              this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((item, indexs) => {
                if (indexs + 1 == index) {
                  return {
                    ...item,
                    ...data.data,
                    chinesePinYin: '',
                    similarPartChinese: '',
                    similarPartEnglish: '',
                    trademarkThreeYear: '',
                    takeActionType: ''
                  }
                }
                return item
              })
            }
            this.applyDatas = [{...this.applyData}, ...this.applyData.quoteTrademarkRejectList].map((item, index) => {
              if (index == 0) {
                item.name = '申请商标'
              } else {
                item.name = '引证商标' + (this.applyData.quoteTrademarkRejectList.length == 1 ? '' : index)
              }
              return item
            })
            let obj = this.applyData

            this.cuchunApplyNoFlag = this.applyData.applyNo
            this.columns = []
            this.columns = [
              {field: 'field', title: '', width: 130, fixed: 'left', showOverflow: false},
              //
              {
                field: 'value', title: '申请商标',  editRender: {name: 'input'}, slots: {
                  // 使用插槽模板渲染
                  default: 'value',
                }, fixed: 'left', minWidth: 329, showOverflow: false
              },
            ]
            this.gridOptions1 = []
            obj.quoteTrademarkRejectList.map((item, index) => {
              // item.debateDimension = []
              this.columns.push({
                field: 'value' + (index + 1), title: '引证商标' + (this.applyDatas.length == 2 ? '' : index + 1), editRender: {name: 'input'}, slots: {
                  // 使用插槽模板渲染
                  default: 'value' + (index + 1),
                }, minWidth: 329, showOverflow: false
              })
              return item
            })
            let objs = null
            this.field.forEach((item, index) => {
              if(this.applyData.trademarkCategory == 1) {
                if(item.title == '商标图形') {
                  return;
                }
              }
              if(this.applyData.trademarkCategory == 2) {
                if(item.title == '商标名称') {
                  return;
                }
              }

              if(this.applyData.trademarkNameCategory == 1) {
                if(item.title == '与申请标近似部分（英文）') {
                  return;
                }
              }

              if(this.applyData.trademarkNameCategory == 2) {
                if(item.title == '与申请标近似部分（中文）') {
                  return;
                }
                if(item.title == '拼音') {
                  return;
                }
              }
              objs = {}
              //第一列值
              objs.field = item.title
              this.columns.map((items, indexs) => {
                if (indexs == 1) {
                  // 第二列值
                  objs.value = obj[item.field]

                } else if (indexs > 1) {
                  objs['value' + (indexs - 1)] = obj.quoteTrademarkRejectList[indexs - 2][item.field]
                }
              })

              this.gridOptions1.push({
                ...objs
              })
            })
          }
        } else {
          this.$message(data.msg)
        }
        this.loadingTable = false
        this.loading3 = false
        this.loading2 = false
        this.loading1 = false
      }).catch(err => {
        this.loading3 = false
        this.loading2 = false
        this.loading1 = false
      })
    },
    cancelRowEvent(row) {
      const $table = this.$refs.tableRef
      if ($table) {
        $table.clearEdit().then(() => {
          // 还原行数据
          $table.revertData(row)
        })
      }
    },
    showUploadMessage() {
      if (this.disableUpload) {
        this.$message("上传文件太频繁")
      }
    },
    handleUploadChange(files, fileList) {
      this.disableUpload = true
      if (this.$refs.myTrademarkRejectUpload.uploadFiles.length > 0) {
        this.$refs.myTrademarkRejectUpload.uploadFiles = fileList.slice(-1)
        let file = this.$refs.myTrademarkRejectUpload.uploadFiles[0]
        this.fileData = file.raw
      } else {
        this.replySourceFileUrl = ""
        this.ocrFileName = ""
      }
      setTimeout(() => {
        this.disableUpload = false
      }, 3000)
    },

    renderPdf() {
      this.downloadAndConvertToUint8Array(this.replySourceFileUrl)
        .then((uint8Array) => {
          this.pdfUint8Array = uint8Array
          PDFJS.getDocument(uint8Array).then(pdf => {
            // 得到PDF的总的页数
            this.numPages = pdf.numPages
            let idName = 'canvas-pdf-'
            let idName1 = 'canvas-pdf1-'
            for (let i = 1; i <= this.numPages; i++) {
              pdf.getPage(i).then((page) => {
                let viewport = page.getViewport(1)

                let canvas = document.getElementById(idName + i)
                let context = canvas.getContext('2d')
                canvas.height = viewport.height
                canvas.width = viewport.width
                let renderContext = {
                  canvasContext: context,
                  viewport
                }
                page.render(renderContext)
              })
            }
          })
        })
    },
    showFullScreenPdf() {
      this.ifFullScreenShowFile = true
      this.$nextTick(() => {
        PDFJS.getDocument(this.pdfUint8Array).then(pdf => {
          // 得到PDF的总的页数
          this.numPages = pdf.numPages
          let idName = 'canvas-pdf1-'
          for (let i = 1; i <= this.numPages; i++) {
            pdf.getPage(i).then((page) => {
              let viewport = page.getViewport(1.5)

              let canvas = document.getElementById(idName + i)
              let context = canvas.getContext('2d')
              canvas.width = viewport.width
              canvas.height = viewport.height
              let renderContext = {
                canvasContext: context,
                viewport
              }
              page.render(renderContext)
            })
          }
        })
      })
    },
    async downloadAndConvertToUint8Array(fileUrl) {
      const response = await this.$axios({
        url: fileUrl,
        method: "GET",
        responseType: "arraybuffer",
      })
      const uint8Array = new Uint8Array(response.data)

      return uint8Array
    },
    getInnerHTML(text) {
      if (text != null) {
        text = text.replaceAll("<", "&lt;")
        text = text.replaceAll(">", "&gt;")
        text = text.replaceAll("\n", "<br/>")

        //图片
        let pattern = /.{2}img.*?img.{2}/g
        let match = text.match(pattern)
        if (match != null) {
          match.forEach(item => {
            let src = item.substring(7, item.length - 7)
            src = "<img style='height: 40px; width: 40px;' src='" + src + "'>"
            text = text.replaceAll(item, src)
          })
        }

        //颜色
        let pattern2 = /.{2}textColor-.*?textColor-.{8}/g
        let match2 = text.match(pattern2)
        if (match2 != null) {
          match2.forEach(item => {
            let color = "#" + item.substring(12, 18)
            let content = item.substring(20, item.length - 20)
            let myHtml = "<span style='color=" + color + "'>" + content + "</span>"
            text = text.replaceAll(item, myHtml)
          })
        }

        return text
      } else {
        return ""
      }
    },

    init(val) {
      if (this.trademarkTitleActive == 0) {
        if (this.fileData == '') {
          this.$message("请先上传文件")
          return
        }
      }

      this.$axios.post("/trademark/file/parse/record/fileChatNumber", {uploadType: this.trademarkTitleActive == 0 ? 1 : 2})
        .then(response => {
          this.fileChatNumber = response.data.data
          if (val == 0) {
            this.disabledFlag = 1
            if (this.trademarkTitleActive == 0) {
              this.zhongjian()
            } else {
              this.refutationArticle()
            }
          } else {
            this.disabledFlag = 2
            if (this.trademarkTitleActive == 0) {
              this.zhongjians()
            } else {
              this.generateReview()
            }
          }
        })
    },
    historyDownloads(item) {
      if (item.storageFilePath != null && item.storageFilePath != '') {
        let prefix = window.location.origin
        let url = prefix + item.storageFilePath
        let fileName = item.storageFileName
        const elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = url
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href) // 释放URL 对象
        document.body.removeChild(elink)
      } else {
        this.loadingText = "文件下载中..."
        this.loading = true
        let type = this.activeName == 'first' ? 1 : 2
        let url = type == 1 ? "/trademark/convey/confirm/message/history" : "/trademark/reject/confirm/message/history"
        this.$axios.get(url, {params: {fileChatNumber: item.fileChatNumber}})
          .then(response => {
            let queryResult = response.data.data
            let wordContextList = null
            let param = null

            switch (type) {
              case 1:
                wordContextList = JSON.parse(queryResult.conveyConfirm.wordAssemblyContext)

                param = {
                  fileChatNumber: item.fileChatNumber,
                  wordContextList: wordContextList
                }

                this.$axios({
                  method: 'post',
                  url: '/trademark/convey/confirm/export/convey/word',
                  headers: {'Content-Type': 'application/json'},
                  data: param
                })
                  .then(res => {
                    let result = res.data.data

                    let prefix = window.location.origin
                    let url = prefix + result.storageFilePath
                    let fileName = result.storageFileName
                    const elink = document.createElement('a')
                    elink.download = fileName
                    elink.style.display = 'none'
                    elink.href = url
                    document.body.appendChild(elink)
                    elink.click()
                    URL.revokeObjectURL(elink.href) // 释放URL 对象
                    document.body.removeChild(elink)
                  })
                  .finally(() => {
                    this.loading = false
                  })
                break
              case 2:
                wordContextList = JSON.parse(queryResult.replyConfirm.wordAssemblyContext)

                param = {
                  fileChatNumber: item.fileChatNumber,
                  wordContextList: wordContextList
                }

                this.$axios({
                  method: 'post',
                  url: '/trademark/reject/confirm/export/reply/word',
                  headers: {'Content-Type': 'application/json'},
                  data: param
                })
                  .then(res => {
                    let result = res.data.data

                    let prefix = window.location.origin
                    let url = prefix + result.storageFilePath
                    let fileName = result.storageFileName
                    const elink = document.createElement('a')
                    elink.download = fileName
                    elink.style.display = 'none'
                    elink.href = url
                    document.body.appendChild(elink)
                    elink.click()
                    URL.revokeObjectURL(elink.href) // 释放URL 对象
                    document.body.removeChild(elink)
                  })
                  .finally(() => {
                    this.loading = false
                  })
                break
            }
          })
      }
    },
    backReview(item, type) {
      let url = type == 1 ? "/trademark/convey/confirm/message/history" : "/trademark/reject/confirm/message/history"
      this.ifConvey = type == 1
      this.fileChatNumber = item.fileChatNumber
      this.ifShowEnd = false
      this.buttonDisabled = false

      this.ifHistoryBackMessage = true

      this.$axios.get(url, {params: {fileChatNumber: item.fileChatNumber}})
        .then(response => {
          this.ifShowConfig = false
          let queryResult = response.data.data
          this.queryData = {wordContextList: [], fileChatNumber: item.fileChatNumber}

          let chatList = null
          switch (type) {
            case 1:
              this.queryData.wordContextList = JSON.parse(queryResult.conveyConfirm.wordAssemblyContext)
              chatList = queryResult.conveyChatMessageList
              this.replySourceFileUrl = queryResult.conveyOriginalFileUrl

              this.historyBackMessage = queryResult.conveyConfirm
              break
            case 2:
              this.queryData.wordContextList = JSON.parse(queryResult.replyConfirm.wordAssemblyContext)
              chatList = queryResult.replyChatMessageList
              this.replySourceFileUrl = queryResult.replyOriginalFileUrl

              this.historyBackMessage = queryResult.replyConfirm
              break
          }
          this.renderPdf()

          this.ifGenerateFromFile = this.replySourceFileUrl != ''
          if (this.ifGenerateFromFile) {
            this.ifShowLeftBlock = true
            this.ifShowRightBlock = true
            this.leftBlockSpan = 8
            this.middleBlockSpan = 8
            this.rightBlockSpan = 8

            this.replySourceFileUrl = ''
          } else {
            this.ifShowLeftBlock = false
            this.ifShowRightBlock = true
            this.leftBlockSpan = 12
            this.middleBlockSpan = 12
            this.rightBlockSpan = 12
          }

          this.generateDomId2MessageId.clear()
          this.generateDomId2Item.clear()
          let aDiv = document.createElement("div")
          this.$nextTick(() => {
            this.queryData.wordContextList.forEach((item, index) => {
              if (item.contextType == 0) {
                this.ganerateFileData = item
              } else if (item.contextType == 1) {
                let aP = document.createElement("p")
                aP.innerHTML = this.getInnerHTML(item.wordText.contextText)
                aP.style.fontSize = item.wordText.fontSize + "px"
                aP.style.lineHeight = 1.5
                aP.style.letterSpacing = '0.5px'
                aP.style.fontFamily = item.wordText.fontFamilyName
                aP.style.textAlign = item.wordText.alignment
                aP.style.fontWeight = item.wordText.boldFlag ? "bolder" : "normal"
                aP.style.textIndent = item.wordText.firstLineIndentedFlag ? "2ch" : "0"

                aP.style.padding = "10px"

                aP.id = 'p' + index
                this.generateDomId2Item.set(aP.id, item)

                aDiv.appendChild(aP)
              } else if (item.contextType == 2) {
                let aDiv2 = document.createElement("div")
                let aTable = document.createElement("table")
                aTable.style.margin = '0 auto'
                aTable.style.maxWidth = '700px'
                let nowRow = 0
                let listLength = item.wordTable.tableList.length

                for (let i = 0; i < listLength; i++) {
                  let aTr = document.createElement("tr")
                  let data = item.wordTable.tableList[i]
                  for (let j = 0; j < data.length; j++) {
                    let dataItem = data[j]
                    let aTd = document.createElement("td")
                    if (j == 0) {
                      aTd.style.minWidth = '80px'
                    } else if (j == 1) {
                      aTd.style.minWidth = '100px'
                    }

                    aTd.innerHTML = this.getInnerHTML(dataItem.contextText)
                    aTd.style.fontSize = dataItem.fontSize + "px"
                    aTd.style.lineHeight = 1.5
                    aTd.style.letterSpacing = '0.5px'
                    aTd.style.fontFamily = dataItem.fontFamilyName
                    aTd.style.textAlign = dataItem.alignment
                    aTd.style.fontWeight = dataItem.boldFlag ? "bolder" : "normal"
                    aTd.style.textIndent = dataItem.firstLineIndentedFlag ? "2ch" : "0"

                    aTd.id = 'td' + index + i + j
                    this.generateDomId2Item.set(aTd.id, dataItem)

                    aTr.appendChild(aTd)
                  }
                  aTable.appendChild(aTr)
                  nowRow++
                }

                aTable.style.padding = "10px"

                aDiv2.appendChild(aTable)
                aDiv.appendChild(aDiv2)
              } else {
                this.generateBrList.push(item)
                let aBr = document.createElement('br')
                aDiv.appendChild(aBr)
              }
            })

            this.htmlData = aDiv.innerHTML
          })

          this.dialogList = []
          if (chatList.length > 0) {
            chatList.forEach(item => {
              this.dialogList.push({
                type: 'user',
                conversationId: item.messageId,
                content: item.content
              })
              this.dialogList.push({
                type: 'assistant',
                conversationId: item.messageId,
                content: item.response
              })
            })
          }
        })
    },
    load() {
      if (this.ifConvey) {
        this.handleDownloadGenerates()
      } else {
        this.handleDownloadGenerates_()
      }
    },
    handleSave() {
      if (this.ifConvey) {
        this.$axios({
          method: 'post',
          url: '/trademark/convey/confirm/save/wordAssemblyContext',
          data: this.queryData
        })
      } else {
        this.$axios({
          method: 'post',
          url: '/trademark/reply/confirm/save/wordAssemblyContext',
          data: this.queryData
        })
      }
    },

    //驳回转达
    zhongjian() {
      if (this.fileData) {
        let form = new FormData()
        form.append("file", this.fileData)
        form.append("fileChatNumber", this.fileChatNumber)
        form.append("businessCategory", 3)

        this.loadingText = "上传文件解析中..."
        this.loading = true
        this.$axios.post("/trademark/file/parse/record/upload", form, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => {
            if (response.data.code == 0) {
              if (this.trademarkTitleActive == 0) {
                this.replySourceFileUrl = response.data.data.originalFileUrl
                this.ocrFileName = response.data.data.originalFileName
              } else {
                this.replySourceFileUrl = response.data.data.replySourceFileUrl
                this.ocrFileName = response.data.data.ocrFileName
              }
              this.loading = false
              this.refutationArticle()
            } else {
              this.loading = false
              this.$message(response.data.msg)
            }
          })
          .catch(err => {
            this.loading = false
            this.$message(err)
          })
      }
    },
    refutationArticle() {
      this.ifConvey = true
      let obj = null
      if (this.trademarkTitleActive == 0) {
        obj = {fileChatNumber: this.fileChatNumber, "uploadType": "1"}
      } else {
        if (this.applyTrademarkNo == null || this.applyTrademarkNo == '') {
          this.$message("请输入申请商标号")
          return;
        }

        if (this.finalRejection == null || this.finalRejection == '') {
          this.$message("请输入驳回意见")
          return;
        }

        obj = {
          applyNo: this.applyTrademarkNo,
          quoteApplyNoList: this.referenceTrademarkNo?.trim().replaceAll(" ", ";").replaceAll("，", ";").replaceAll(",", ";").replaceAll("；", ";").split(";"),
          rejectInformationList: this.finalRejection.split("\n"),
          fileChatNumber: this.fileChatNumber,
          uploadType: this.trademarkTitleActive == 0 ? 1 : 2
        }
      }
      this.loadingText = "正在提取商标信息中..."
      this.loading = true
      this.$axios.post('/trademark/convey/confirm/assembly', obj)
        .then(({data}) => {
          if (data.code == 0) {
            this.panduan = this.trademarkTitleActive
            this.loading = false
            this.historyFlag = false
            this.fullDetail = data.data
            let obj = JSON.parse(data.data.trademarkAssemblyInfo)
            this.tableData = [{
              "name": '申请商标',
              "agencyAddress": obj.agencyAddress,
              "agencyName": obj.agencyName,
              "applicantAddress": obj.applicantAddress,
              "applicantPersonName": obj.applicantPersonName,
              "applyDate": obj.applyDate,
              "applyNo": obj.applyNo,
              "niceCode": obj.niceCode,
              "niceCodeDetail": obj.niceCodeDetail,
              "niceCodeName": obj.niceCodeName,
              "rowIndex": obj.rowIndex,
              "similarGroup": obj.similarGroup,
              "similarGroupDetail": obj.similarGroupDetail,
              "trademarkImageUrl": obj.trademarkImageUrl,
              "trademarkName": obj.trademarkName,
              "trademarkStatus": obj.trademarkStatus
            }]
            if (obj.quoteConveyList) {
              this.tableData = [...this.tableData, ...obj.quoteConveyList.map((item, index) => {
                item.name = '引证商标' + (this.applyDatas.length == 2 ? '' : index + 1)
                return item
              })]
            }
          } else {
            this.$message(data.msg)
          }
          this.loading = false
        })
        .catch(err => {
          this.$message(err.data.msg)
          this.loading = false
        })
    },
    refutationArticleGenerate(ifRegenerate) {
      let url = "/trademark/convey/confirm/create/convey/word"

      if (!this.ifHistoryBackMessage) {

        if (!this.fullDetail.trademarkCategory) {
          this.$message.error('商标类型必须选择');
          return
        }

        if (!this.fullDetail.rejectReasonType) {
          this.$message.error('驳回信息必须选择');
          return
        }

        if (this.fullDetail.rejectReasonType == 2) {
          if (!this.fullDetail.rejectType) {
            this.$message.error('驳回类型必须选择');
            return
          }
        }

        if (!this.fullDetail.customerName) {
          this.$message.error('客户名称必须填写');
          return
        }

        if (!this.fullDetail.rejectNoticeDate) {
          this.$message.error('驳回通知书发文日期必须填写');
          return
        }
      }

      if (ifRegenerate) {
        url = url + "/again"
      }

      this.ifGenerateFromFile = true
      this.ifShowLeftBlock = true
      this.ifShowRightBlock = true
      this.leftBlockSpan = 8
      this.middleBlockSpan = 8
      this.rightBlockSpan = 8

      this.renderPdf()

      this.loading = true
      this.loadingText = "驳文转达申请文件生成中，请耐心等待..."
      this.ifShowConfig = false
      this.fakeHtmlData = ""
      this.fakeHtmlProgressNumber = 0
      this.fakeHtml = true
      this.buttonDisabled = true

      this.$nextTick(() => {
        if (this.panduan == 1) {
          this.handleRetractButton('left1')
        } else {
          this.handleRetractButton('left2')
        }
      })
      this.disabledFlag = 0
      this.ifShowEnd = true

      let data = this.ifHistoryBackMessage ? this.historyBackMessage : {
        ...this.fullDetail,
        applyNo: this.tableData[0].applyNo,
        quoteApplyNo: this.tableData.filter(item => item.name != '申请商标').map(item => item.applyNo).join(','),
        trademarkAssemblyInfo: JSON.stringify({
          "agencyAddress": this.tableData[0].agencyAddress,
          "agencyName": this.tableData[0].agencyName,
          "applicantAddress": this.tableData[0].applicantAddress,
          "applicantPersonName": this.tableData[0].applicantPersonName,
          "applyDate": this.tableData[0].applyDate,
          "applyNo": this.tableData[0].applyNo,
          "niceCode": this.tableData[0].niceCode,
          "niceCodeDetail": this.tableData[0].niceCodeDetail,
          "niceCodeName": this.tableData[0].niceCodeName,
          "rowIndex": this.tableData[0].rowIndex,
          "similarGroup": this.tableData[0].similarGroup,
          "similarGroupDetail": this.tableData[0].similarGroupDetail,
          "trademarkImageUrl": this.tableData[0].trademarkImageUrl,
          "trademarkName": this.tableData[0].trademarkName,
          "trademarkStatus": this.tableData[0].trademarkStatus,
          quoteConveyList: this.tableData.filter(item => item.name != '申请商标')
        })
      }

      this.$axios.post(url, data).then(response => {
        if (response.data.code === 10003) {
          window.clearTimeout(this.timer);
          this.$message.error(response.data.msg)
          this.ifShowConfig = true
          this.loading = false
          this.$refs.myTrademarkRejectUpload.uploadFiles = []
          return
        } else if (response.data.code === 501) {
          this.$message.error("很抱歉，申请号暂时识别不到，请您进行手动输入")
          this.ifShowConfig = true
          this.loading = false
          this.$refs.myTrademarkRejectUpload.uploadFiles = []
          return
        } else {
          this.queryData = response.data.data
          if (this.queryData.wordContextList != null) {
            this.handleQueryDatas(this.queryData.wordContextList)
          } else {
            this.$message(response.data.msg)
          }
        }
      })
        .catch(err => {
          if (this.$axios.isCancel(err)) {
            this.ifShowConfig = true
            this.loading = false
            this.$refs.myTrademarkRejectUpload.uploadFiles = []
            return
          }
          if (err.data && err.data.msg) {
            this.$message(err.data.msg)
          }
          this.ifShowConfig = true
          this.loading = false
          this.$refs.myTrademarkRejectUpload.uploadFiles = []
          this.buttonDisabled = false
        })
    },
    handleQueryDatas(data) {
      this.generateDomId2MessageId.clear()
      this.generateDomId2Item.clear()
      let aDiv = document.createElement("div")
      this.$nextTick(() => {
        data.forEach((item, index) => {
          if (item.chatFlag && item.wordText.contextText) {
            let aEditDiv = document.createElement("div")
            aEditDiv.id = 'editDiv' + index

            aEditDiv.style.fontSize = item.wordText.fontSize + "px"
            aEditDiv.style.lineHeight = 1.5
            aEditDiv.style.letterSpacing = '0.5px'
            aEditDiv.style.fontFamily = item.wordText.fontFamilyName
            aEditDiv.style.textAlign = item.wordText.alignment
            aEditDiv.style.fontWeight = item.wordText.boldFlag ? "bolder" : "normal"
            aEditDiv.style.textIndent = item.wordText.firstLineIndentedFlag ? "2ch" : "0"

            this.generateDomId2MessageId.set('editDiv' + index, item.wordText.contextText)
            this.generateDomId2Item.set('editDiv' + index, item)
            aDiv.appendChild(aEditDiv)
          } else {
            if (item.contextType == 0) {
              this.ganerateFileData = item
            } else if (item.contextType == 1) {
              let aP = document.createElement("p")
              aP.innerHTML = this.getInnerHTML(item.wordText.contextText)
              aP.style.fontSize = item.wordText.fontSize + "px"
              aP.style.lineHeight = 1.5
              aP.style.letterSpacing = '0.5px'
              aP.style.fontFamily = item.wordText.fontFamilyName
              aP.style.textAlign = item.wordText.alignment
              aP.style.fontWeight = item.wordText.boldFlag ? "bolder" : "normal"
              aP.style.textIndent = item.wordText.firstLineIndentedFlag ? "2ch" : "0"

              aP.style.padding = "10px"

              aP.id = 'p' + index
              this.generateDomId2Item.set(aP.id, item)

              aDiv.appendChild(aP)
            } else if (item.contextType == 2) {
              let aDiv2 = document.createElement("div")
              let aTable = document.createElement("table")
              aTable.style.margin = '0 auto'
              aTable.style.maxWidth = '700px'
              let nowRow = 0
              let listLength = item.wordTable.tableList.length

              for (let i = 0; i < listLength; i++) {
                let aTr = document.createElement("tr")
                let data = item.wordTable.tableList[i]
                for (let j = 0; j < data.length; j++) {
                  let dataItem = data[j]
                  let aTd = document.createElement("td")
                  if (j == 0) {
                    aTd.style.minWidth = '80px'
                  } else if (j == 1) {
                    aTd.style.minWidth = '100px'
                  }

                  aTd.innerHTML = this.getInnerHTML(dataItem.contextText)
                  aTd.style.fontSize = dataItem.fontSize + "px"
                  aTd.style.lineHeight = 1.5
                  aTd.style.letterSpacing = '0.5px'
                  aTd.style.fontFamily = dataItem.fontFamilyName
                  aTd.style.textAlign = dataItem.alignment
                  aTd.style.fontWeight = dataItem.boldFlag ? "bolder" : "normal"
                  aTd.style.textIndent = dataItem.firstLineIndentedFlag ? "2ch" : "0"

                  aTd.id = 'td' + index + i + j
                  this.generateDomId2Item.set(aTd.id, dataItem)

                  aTr.appendChild(aTd)
                }
                aTable.appendChild(aTr)
                nowRow++
              }

              aTable.style.padding = "10px"

              aDiv2.appendChild(aTable)
              aDiv.appendChild(aDiv2)
            } else {
              this.generateBrList.push(item)
              let aBr = document.createElement('br')
              aDiv.appendChild(aBr)
            }
          }
        })

        this.htmlData = aDiv.innerHTML

        this.fakeHtml = false
        this.loading = false

        this.handleGetMessageContentss()
      })
    },
    handleGetMessageContentss() {
      let generateDomId2MessageIdArray = []
      this.generateDomId2MessageId.forEach((value, key) => {
        generateDomId2MessageIdArray.push(key + "~~~" + value)
      })

      //递归调用获取信息
      this.handleGetMessageContentsForeachs(generateDomId2MessageIdArray)
    },
    handleGetMessageContentsForeachs(generateDomId2MessageIdList) {
      let that = this
      if (generateDomId2MessageIdList.length > 0) {
        let domId2MessageId1 = generateDomId2MessageIdList[0]
        let domId = domId2MessageId1.split("~~~")[0]
        let messageId = domId2MessageId1.split("~~~")[1]
        // 创建一个新的 CancelToken 实例
        this.stopSignal = axios.CancelToken.source();
        this.$axios.get(
          "/trademark/convey/chat/message/stream/" + messageId,
          {
            timeout: 10 * 60 * 10000,
            cancelToken: this.stopSignal.token, // 将 cancelToken 放在配置中
            onDownloadProgress: (event) => {
              this.flagw = true
              let content = event.target.response
              //换行
              let contentListNew = []
              let contentList = content.split("\n")
              contentList.forEach(item => {
                if (item != "") {
                  contentListNew.push("<p style='margin: 0'>" + item + "</p>")
                }
              })
              content = contentListNew.join("<br>")

              //加粗
              content = content.replaceAll("**", "%%%")
              let pattern = /%%%.*?%%%/g
              let arr = content.match(pattern)
              if (arr) {
                arr.forEach(item => {
                  let targetText = "<b>" + item.replaceAll("%%%", "") + "</b>"
                  content = content.replace(item, targetText)
                })
              }

              var objElement = document.createElement("div")
              objElement.innerHTML = that.htmlData

              let dom = objElement.querySelector("#" + domId)
              dom.innerHTML = content

              that.htmlData = objElement.innerHTML
            }
          }
        )
          .then(() => {
            this.flagw = false
            let nextList = generateDomId2MessageIdList.slice(1, generateDomId2MessageIdList.length)
            that.handleGetMessageContentsForeachs(nextList)
          }).catch(err => {
          this.flagw = false
        })
      } else {
        this.buttonDisabled = false

        this.generateDownloadList = []
        this.generateDownloadList.push(this.ganerateFileData)
        let brNumber = 0
        var objElement = document.createElement("div")
        objElement.innerHTML = this.htmlData
        objElement.childNodes.forEach(item => {
          if (item.nodeName == "BR") {
            this.generateDownloadList.push(this.generateBrList[brNumber])
            brNumber++
          } else if (item.nodeName == "P") {
            let generateDownloadItem = {
              "contextType": 1,
              "wordText": {
                "contextText": this.getDownloadContextType(item.innerHTML),
                "fontSize": Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) > 0 ? Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) : 11,
                "fontFamilyName": item.style.fontFamily,
                "alignment": item.style.textAlign,
                "boldFlag": item.style.fontWeight == "bolder",
                "firstLineIndentedFlag": item.style.textIndent == "2ch",
                "headingStyle": item.id == '' ? "Normal" : this.generateDomId2Item.get(item.id).wordText.headingStyle
              },
              "wordTable": null
            }
            this.generateDownloadList.push(generateDownloadItem)
          } else if (item.nodeName == "DIV" && !item.id.startsWith("editDiv")) {
            let aTable, aTbody, trList = []
            item.childNodes.forEach(node => {
              if (node.nodeName == "TABLE") {
                aTable = node
              }
            })
            aTable.childNodes.forEach(node => {
              if (node.nodeName == "TBODY") {
                aTbody = node
              }
            })
            aTbody.childNodes.forEach(node => {
              if (node.nodeName == "TR") {
                trList.push(node)
              }
            })
            let rowLength = trList.length
            let colLength = 0
            trList[0].childNodes.forEach(node => {
              if (node.nodeName == "TD") {
                colLength++
              }
            })

            let generateDownloadItem = {
              "contextType": 2,
              "wordText": null,
              "wordTable": {
                "rowNumber": rowLength,
                "columnNumber": colLength,
                "tableList": []
              }
            }

            trList.forEach(aTr => {
              let itemList = []
              aTr.childNodes.forEach(aTd => {
                if (aTd.nodeName == "TD") {
                  let item = {
                    "contextText": this.getDownloadContextType(aTd.innerHTML),
                    "fontSize": Number(aTd.style.fontSize.slice(0, aTd.style.fontSize.length - 2)) > 0 ? Number(aTd.style.fontSize.slice(0, aTd.style.fontSize.length - 2)) : 11,
                    "fontFamilyName": aTd.style.fontFamily,
                    "alignment": aTd.style.textAlign,
                    "boldFlag": aTd.style.fontWeight == "bolder",
                    "firstLineIndentedFlag": aTd.style.textIndent == "2ch",
                    "headingStyle": aTd.id == '' ? "Normal" : this.generateDomId2Item.get(aTd.id).headingStyle
                  }
                  itemList.push(item)
                }
              })
              generateDownloadItem.wordTable.tableList.push(itemList)
            })
            this.generateDownloadList.push(generateDownloadItem)
          } else if (item.nodeName == "DIV" && item.id.startsWith("editDiv")) {
            let pList = item.querySelectorAll("p")
            if (pList.length > 0) {
              pList.forEach(it => {
                if (it.innerHTML != null && it.innerHTML != "") {
                  let generateDownloadItem = {
                    "contextType": 1,
                    "wordText": {
                      "contextText": it.innerHTML.replaceAll("<b>", "**").replaceAll("<b/>", "**").replaceAll("<br>", "\n"),
                      "fontSize": Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) > 0 ? Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) : 11,
                      "fontFamilyName": item.style.fontFamily,
                      "alignment": item.style.textAlign,
                      "boldFlag": item.style.fontWeight == "bolder",
                      "firstLineIndentedFlag": item.style.textIndent == "2ch",
                      "headingStyle": item.id == '' ? "Normal" : this.generateDomId2Item.get(item.id).wordText.headingStyle
                    },
                    "wordTable": null
                  }
                  this.generateDownloadList.push(generateDownloadItem)
                }
              })
            }
          }
        })

        this.queryData.wordContextList = this.generateDownloadList

        this.$axios({
          method: 'post',
          url: '/trademark/convey/confirm/save/wordAssemblyContext',
          data: this.queryData
        })
        this.ifShowEnd = false
      }
    },
    handleDownloadGenerates() {
      this.generateDownloadList = []
      this.generateDownloadList.push(this.ganerateFileData)
      let brNumber = 0
      var objElement = document.createElement("div")
      objElement.innerHTML = this.htmlData
      objElement.childNodes.forEach(item => {
        if (item.nodeName == "BR") {
          this.generateDownloadList.push(this.generateBrList[brNumber])
          brNumber++
        } else if (item.nodeName == "P") {
          let generateDownloadItem = {
            "contextType": 1,
            "wordText": {
              "contextText": this.getDownloadContextType(item.innerHTML),
              "fontSize": Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) > 0 ? Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) : 11,
              "fontFamilyName": item.style.fontFamily,
              "alignment": item.style.textAlign,
              "boldFlag": item.style.fontWeight == "bolder",
              "firstLineIndentedFlag": item.style.textIndent == "2ch",
              "headingStyle": item.id == '' ? "Normal" : this.generateDomId2Item.get(item.id).wordText.headingStyle
            },
            "wordTable": null
          }
          this.generateDownloadList.push(generateDownloadItem)
        } else if (item.nodeName == "DIV" && !item.id.startsWith("editDiv")) {
          let aTable, aTbody, trList = []
          item.childNodes.forEach(node => {
            if (node.nodeName == "TABLE") {
              aTable = node
            }
          })
          aTable.childNodes.forEach(node => {
            if (node.nodeName == "TBODY") {
              aTbody = node
            }
          })
          aTbody.childNodes.forEach(node => {
            if (node.nodeName == "TR") {
              trList.push(node)
            }
          })
          let rowLength = trList.length
          let colLength = 0
          trList[0].childNodes.forEach(node => {
            if (node.nodeName == "TD") {
              colLength++
            }
          })

          let generateDownloadItem = {
            "contextType": 2,
            "wordText": null,
            "wordTable": {
              "rowNumber": rowLength,
              "columnNumber": colLength,
              "tableList": []
            }
          }

          trList.forEach(aTr => {
            let itemList = []
            aTr.childNodes.forEach(aTd => {
              if (aTd.nodeName == "TD") {
                let item = {
                  "contextText": this.getDownloadContextType(aTd.innerHTML),
                  "fontSize": Number(aTd.style.fontSize.slice(0, aTd.style.fontSize.length - 2)) > 0 ? Number(aTd.style.fontSize.slice(0, aTd.style.fontSize.length - 2)) : 11,
                  "fontFamilyName": aTd.style.fontFamily,
                  "alignment": aTd.style.textAlign,
                  "boldFlag": aTd.style.fontWeight == "bolder",
                  "firstLineIndentedFlag": aTd.style.textIndent == "2ch",
                  "headingStyle": aTd.id == '' ? "Normal" : this.generateDomId2Item.get(aTd.id).headingStyle
                }
                itemList.push(item)
              }
            })
            generateDownloadItem.wordTable.tableList.push(itemList)
          })
          this.generateDownloadList.push(generateDownloadItem)
        } else if (item.nodeName == "DIV" && item.id.startsWith("editDiv")) {
          let pList = item.querySelectorAll("p")
          if (pList.length > 0) {
            pList.forEach(it => {
              if (it.innerHTML != null && it.innerHTML != "") {
                let generateDownloadItem = {
                  "contextType": 1,
                  "wordText": {
                    "contextText": it.innerHTML.replaceAll("<b>", "**").replaceAll("<b/>", "**").replaceAll("<br>", "\n"),
                    "fontSize": Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) > 0 ? Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) : 11,
                    "fontFamilyName": item.style.fontFamily,
                    "alignment": item.style.textAlign,
                    "boldFlag": item.style.fontWeight == "bolder",
                    "firstLineIndentedFlag": item.style.textIndent == "2ch",
                    "headingStyle": item.id == '' ? "Normal" : this.generateDomId2Item.get(item.id).wordText.headingStyle
                  },
                  "wordTable": null
                }
                this.generateDownloadList.push(generateDownloadItem)
              }
            })
          }
        }
      })

      this.loading = true
      this.loadingText = "文件导出中，请稍候..."
      this.queryData.wordContextList = this.generateDownloadList

      this.$axios({
        method: 'post',
        url: '/trademark/convey/confirm/export/convey/word',
        headers: {'Content-Type': 'application/json'},
        data: this.queryData
      })
        .then(res => {
          this.historyDownloads(res.data.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    //查询驳回转达申请确认表的分页数据
    queryHistory() {
      let param = {
        current: this.trademarkHistoryQueryParam.pageNum,
        size: this.trademarkHistoryQueryParam.pageSize,
        orderType: 'desc',
        orderField: 'createTime'
      }
      this.$axios({
        method: 'get',
        url: '/trademark/convey/confirm/history/list/page',
        params: param
      }).then(response => {
        this.trademarkHistoryList = response.data.data.records
        this.trademarkHistoryTotal = response.data.data.total
      })
    },
    handleTrademarkHistorySizeChange(val) {
      this.trademarkHistoryQueryParam.pageSize = val
      this.queryHistory()
    },
    handleTrademarkHistoryCurrentChange(val) {
      this.trademarkHistoryQueryParam.pageNum = val
      this.queryHistory()
    },
    handleSendMessage() {
      this.$axios.post("/trademark/convey/chat/message/create", {
        fileChatNumber: this.fileChatNumber,
        content: this.myAIDialogInput,
        chatType: 3
      })
        .then(response => {
          let myConversationId = response.data.data.messageId

          this.dialogList.push({
            type: 'user',
            conversationId: myConversationId,
            content: this.myAIDialogInput
          })
          this.dialogList.push({
            type: 'assistant',
            conversationId: myConversationId,
            content: ""
          })

          this.myAIDialogInput = ""
          this.handleScrollToButtom('AIDialogueContent')
          this.myAIDialogInput = ""

          this.handleGetResponseMessage(myConversationId)
        })
    },
    handleGetResponseMessage(conversationId) {
      // 创建一个新的 CancelToken 实例
      this.stopSignal2 = axios.CancelToken.source();
      this.$axios.get(
        "/trademark/convey/chat/message/stream/" + conversationId,
        {
          timeout: 10 * 60 * 10000,
          cancelToken: this.stopSignal2.token, // 将 cancelToken 放在配置中
          onDownloadProgress: (event) => {
            this.chatLoading = true
            //换行
            let content = event.target.response.replaceAll("\n", "<br>")
            //加粗
            content = content.replaceAll("**", "%%%")
            let pattern = /%%%.*?%%%/g
            let arr = content.match(pattern)
            if (arr) {
              arr.forEach(item => {
                let targetText = "<b>" + item.replaceAll("%%%", "") + "</b>"
                content = content.replace(item, targetText)
              })
            }
            //覆盖
            this.dialogList.forEach(myItem => {
              if (myItem.conversationId == conversationId && myItem.type == 'assistant') {
                myItem.content = content
              }
            })
            //滚动
            this.handleScrollToButtom("AIDialogueContent")
          }
        }
      )
        .then(() => {
          this.chatLoading = false
        })
        .catch(() => {
          this.chatLoading = false
        })
    },

    //驳回复审
    zhongjians() {
      if (this.fileData) {
        let form = new FormData()
        form.append("file", this.fileData)
        form.append("fileChatNumber", this.fileChatNumber)
        form.append("businessCategory", 2)
        this.loadingText = "上传文件解析中..."
        this.loading = true
        this.$axios.post("/trademark/file/parse/record/upload", form, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
          if (response.data.code == 0) {
            if (this.trademarkTitleActive == 0) {
              this.replySourceFileUrl = response.data.data.originalFileUrl
              this.ocrFileName = response.data.data.originalFileName
            } else {
              this.replySourceFileUrl = response.data.data.replySourceFileUrl
              this.ocrFileName = response.data.data.ocrFileName
            }
            this.loading = false
            this.generateReview()
          } else {
            this.loading = false
            this.$message(response.data.msg)
          }
        })
          .catch(err => {
            this.loading = false
            this.$message(err)
          })
      }
    },
    restoreSelection() {
      // 根据 selectedRows 恢复当前页选中状态
      const table = this.$refs.table;
      this.powerFirstTable.forEach((row) => {
        const isSelected = this.selectedRows.some((item) => item.id === row.id);
        table?.toggleRowSelection(row, isSelected);
      });
    },
    handleSelectionChange(selection) {
      // 移除当前页未选中的数据，并合并选中的数据
      const currentIds = selection.map((row) => row.id);
      this.selectedRows = [
        ...this.selectedRows.filter(
          (row) => !this.powerFirstTable.some((item) => item.id === row.id)
        ),
        ...selection,
      ];
      this.applyData.previousSimilarRejectList = this.selectedRows.length > 0 ? this.selectedRows : null
    },
    previousSimilarEvent(val) {
      if (!this.applyData.trademarkCategory) {
        this.$message({
          message: '请选择商标类型',
          type: 'warning'
        });
        this.applyData.previousSimilarBlag = false
        return
      }
      if (val) {
        if(this.applyData.trademarkCategory == 1 || this.applyData.trademarkCategory == 2){
          this.similar()
        }else if(this.applyData.trademarkCategory == 3){
          this.similars()
        }
      }
    },
    async urlToBase64(url) {
      try {
        // 使用 fetch 获取图片
        const response = await fetch(url);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

        // 将图片内容转换为 Blob
        const blob = await response.blob();

        // 使用 FileReader 将 Blob 转换为 Base64
        return await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error('Failed to convert URL to Base64:', error);
        return null;
      }
    },

    // // 示例调用
    //     const imageUrl = "http://yourdomain.com/group1/M00/76/79/wKgKH2UDFCGAeF-7AAAY_Y4TC1A698.png";
    // urlToBase64(imageUrl).then((base64) => {
    //   if (base64) {
    //     console.log('Base64 String:', base64);
    //   } else {
    //     console.log('Failed to convert image to Base64');
    //   }
    // });
    similarCurrentChange(val) {
      this.similarCurrent = val
      if(this.applyData.trademarkCategory == 1 || this.applyData.trademarkCategory == 2){
        this.similar()
      }else if(this.applyData.trademarkCategory == 3){
        this.similars()
      }
    },
    similar() {
      // 入参
      this.loadingSimilar = true
      let obj = {
        country: 'CN',
        "lang":"zh",
        //商标名称 string
        keywork: this.applyData.trademarkCategory == 1 ? this.applyData.trademarkName : undefined,
        //商标的图片地址 string
        base64File: this.applyData.trademarkCategory != 1 ? this.urlToBase64(this.queryDataUrl(this.applyData.trademarkImageUrl)) : undefined,
        secondQuery: {
          "applicant":this.applyData.applicantPersonName,
          "tmstatuscode": this.applyData.trademarkCategory != 1 ? "1;10" : undefined,
          "similargroup": this.applyData.trademarkCategory != 1 ? this.applyData.similarGroup : undefined,
        },
        "tmstatuscode": this.applyData.trademarkCategory == 1 ? "1;10" : undefined,
        "similargroup": this.applyData.trademarkCategory == 1 ? this.applyData.similarGroup : undefined,
        "startScore": this.applyData.trademarkCategory == 1 ? 50 : undefined,
        current: this.similarCurrent,
        size: this.similarSize
      }
      if(this.applyData.trademarkCategory == 1) {
        this.$axios.post('/trademark/file/parse/record/similar/trademark', obj).then(({data}) => {
          this.loadingSimilar = false
          if (data.code == 0) {
            this.powerFirstTable = data.data.records.map(item => {
              item.trademarkImageUrl = item.imageUrl
              item.trademarkName = item.sbmc
              item.niceCode  = item.intcls
              item.applyNo = item.appno
              item.applicantAddress = item.tmaddress
              item.applyDate = item.appdate
              item.trademarkStatus = item.tmstatus
              item.similarGroupDetail = item.similargroup
              item.agencyName  = item.tmagent
              item.registerDate = item.regdate
              return item
            })

            this.similarTotal = data.data.total
            // 恢复当前页的选中状态
            this.$nextTick(() => {
              this.$refs.multipleTable.doLayout()
              this.restoreSelection();
            });
          }
        }).catch(err => {
          this.loadingSimilar = false
        })
      }

      if(this.applyData.trademarkCategory != 1) {
          this.urlToBase64(this.queryDataUrl(this.applyData.trademarkImageUrl)).then(res => {
            obj.base64File = res.replace(/^data:image\/\w+;base64,/, "")
            this.$axios.post('/trademark/file/parse/record/similar/trademark', obj).then(({data}) => {
              this.loadingSimilar = false
              if (data.code == 0) {
                this.powerFirstTable = data.data.records.map(item => {
                  item.trademarkImageUrl = item.imageUrl
                  item.trademarkName = item.sbmc
                  item.niceCode  = item.intcls
                  item.applyNo = item.appno
                  item.applicantAddress = item.tmaddress
                  item.applyDate = item.appdate
                  item.trademarkStatus = item.tmstatus
                  item.similarGroupDetail = item.similargroup
                  item.agencyName  = item.tmagent
                  item.registerDate = item.regdate
                  return item
                })
                this.similarTotal = data.data.total
                // 恢复当前页的选中状态
                this.$nextTick(() => {
                  this.$refs.multipleTable.doLayout()
                  this.restoreSelection();
                });
              }
            }).catch(err => {
              this.loadingSimilar = false
            })
          })

      }

    },
    similars() {
      let base64 = null
      this.loadingSimilar = true
      let list = []
      this.urlToBase64(this.queryDataUrl(this.applyData.trademarkImageUrl)).then(res => {
        base64= res.replace(/^data:image\/\w+;base64,/, "")
        list =[this.$axios.post('/trademark/file/parse/record/similar/trademark', {
          country: 'CN',
          //商标名称 string
          keywork: this.applyData.trademarkName ,
          "lang":"zh",
          secondQuery: {
            "applicant":this.applyData.applicantPersonName,
          },
          "tmstatuscode":"1;10",
          "similargroup":this.applyData.similarGroup,
          "startScore": 50,
          current: this.similarCurrent,
          size: this.similarSize
        }),this.$axios.post('/trademark/file/parse/record/similar/trademark', {
          country: 'CN',
          //商标的图片地址 string
          base64File:   base64,
          "lang":"zh",
          secondQuery: {
            "applicant":this.applyData.applicantPersonName,
            "tmstatuscode":"1;10",
            "similargroup":this.applyData.similarGroup,
          },

          current: this.similarCurrent,
          size: this.similarSize
        })]
        Promise.all(list).then(results => {
          this.loadingSimilar = false
          this.powerFirstTable = results[0].data.data.records.map(item => {
            item.trademarkImageUrl = item.imageUrl
            item.trademarkName = item.sbmc
            item.niceCode  = item.intcls
            item.applyNo = item.appno
            item.applicantAddress = item.tmaddress
            item.applyDate = item.appdate
            item.trademarkStatus = item.tmstatus
            item.similarGroupDetail = item.similargroup
            item.agencyName  = item.tmagent
            item.registerDate = item.regdate
            return item
          }).concat(results[1].data.data.records.map(item => {
            item.registerDate = item.regdate
            item.trademarkImageUrl = item.imageUrl
            item.trademarkName = item.sbmc
            item.niceCode  = item.intcls
            item.applyNo = item.appno
            item.applicantAddress = item.tmaddress
            item.applyDate = item.appdate
            item.trademarkStatus = item.tmstatus
            item.similarGroupDetail = item.similargroup
            item.registerDate = item.regdate
            item.agencyName  = item.tmagent
            return item
          }))
          this.similarTotal = (results[0].data.data.total > results[1].data.data.total) ? results[0].data.data.total : results[1].data.data.total
          // 恢复当前页的选中状态
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
            this.restoreSelection();
          });
        }).catch(() => {
          this.loadingSimilar = false
        })
      })
    },
    handleCellClick({row, column}) {
      if (row.field == '申请号' || row.field == '拼音' || (row.field == '与申请标近似部分（英文）' && column.field != 'value')|| (row.field == '与申请标近似部分（中文）' && column.field != 'value')) {
        // 触发当前单元格进入编辑模式
        return true
      } else {
        return false
      }
    },
    messagesIdArr(item) {
      return this.$axios.post("/trademark/reject/chat/message/create", {
        //11879819820236161024
        fileChatNumber: this.fileChatNumber,
        // 驳回问题类型：0通用会话,10整体外观方面，20显著部分，30呼叫发音，40商标含义，50商品服务，60生产部门，70销售渠道，80消费对象
        questionType: item.questionType,
        //引证商标申请号
        quoteApplyNo: item.applyNo,
        content: item.content
      })
    },
    getMessageList(item, index) {
      // 为每个请求创建独立的 cancelToken
      const cancelToken = this.$axios.CancelToken.source();
      this.requestTokens.push(cancelToken);
      let that = this
      const container = document.querySelector('.myAiGenerateContent');
      container.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return this.$axios.get(`/trademark/reject/chat/message/stream/${item.data.data}`, {
        timeout: 10 * 60 * 1000,
        cancelToken: cancelToken.token,
        onDownloadProgress: (event) => {

          that.flagw = true
          let content = event.target.response
          that.$set(that.totalMessages, index, {
            id: item.data.data,
            ...that.totalMessages[index],
            as: content,
          });

        },
      });
    },
    getCommonSubstring(words) {
      // 找出最短的单词作为基准
      const baseWord = words.reduce((shortest, current) =>
        current.length < shortest.length ? current : shortest, words[0]);

      let commonSubstring = '';

      // 遍历基准单词的所有子字符串，检查每个子字符串是否出现在其他单词中
      for (let length = 1; length <= baseWord.length; length++) {
        for (let start = 0; start <= baseWord.length - length; start++) {
          const substring = baseWord.substring(start, start + length);
          if (words.every(word => word.toLowerCase().includes(substring))) {
            if (substring.length > commonSubstring.length) {
              commonSubstring = substring;  // 更新最大长度的公共子字符串
            }
          }
        }
      }

      return commonSubstring;
    },
    similarTrademark() {
      this.similarTrademarkData = []
      this.$axios.post("/trademark/file/parse/record/similar/trademark", {
        "current": 1,
        "keywork": this.commonSubstring.word,
        size: 50,
        "niceList": this.applyData.niceCode,
        "country": "CN",
        "lang": "zh",
        "queryWay": "",
        "secondQuery": {"tmstatuscode": "1;10"},
        "startScore": 50
      }).then(({data}) => {
        if (data.code == 0) {
          if (data.data.records.length >= 50) {
            this.similarTrademarkData = data.data.records
          } else {
            this.$axios.post("/trademark/file/parse/record/similar/trademark", {
              "current": 1,
              "keywork": this.commonSubstring.word,
              size: 50,
              "country": "CN",
              "lang": "zh",
              "queryWay": "",
              "secondQuery": {"tmstatuscode": "1;10"},
              "startScore": 50
            }).then(({data}) => {
              if (data.code == 0) {
                this.similarTrademarkData = data.data.records
              }
            })
          }
        }
      })
    },
    revokedEvent(item) {
      return this.$axios.post("/trademark/file/parse/record/similar/trademark", {
        "current": 1,
        "size": 50,
        "country": "CN",
        "lang": "zh",
        "appNo": item,
        "queryWay": ""
      })
    },
    revoked() {
      this.revokedData = []
      this.citationRightsData = []
      let list = []
      this.applyDatas.map((item, index) => {
        if (index != 0) {
          this.citationRightsData.push(item)
          if (item.revokeFlag || item.trademarkStatus == '无效') {
            list.push(this.revokedEvent(item.applyNo))
          }
        }
      })
      this.citationRightsData = this.citationRightsData.concat(this.applyData.previousSimilarRejectList ? this.applyData.previousSimilarRejectList : [])
      // if (list.length > 0) {
      //   Promise.all(list).then(results => {
      //     this.revokedData = results.map(item => item.data)
      //     this.$nextTick(() => {
      //       this.serialNumber()
      //     })
      //   })
      // }
    },
    save() {
      let obj = null
      this.loading = false
      let arr = this.rule()
// 定义需要排在前面的 questionType 值
      const frontTypes = [11, 12, 13];

// 先排序，把 frontTypes 排到前面，确保相同 applyNo 的 [11, 12, 13] 里第一条在前
      arr.sort((a, b) => {
        const aInFront = frontTypes.includes(a.questionType);
        const bInFront = frontTypes.includes(b.questionType);

        if (aInFront && !bInFront) return -1;
        if (!aInFront && bInFront) return 1;
        return 0; // 其他情况保持原顺序
      });

// 处理 show 逻辑
      const applyNoMap = new Map();

      arr.forEach(item => {
        const { applyNo, questionType } = item;

        if (!applyNoMap.has(applyNo)) {
          applyNoMap.set(applyNo, { hasFirstFrontType: false });
        }

        const applyData = applyNoMap.get(applyNo);

        if (frontTypes.includes(questionType)) {
          if (!applyData.hasFirstFrontType) {
            item.show = true;  // 该 applyNo 下第一个符合 [11, 12, 13] 的
            applyData.hasFirstFrontType = true;
          } else {
            item.show = false; // 其余设为 false
          }
        } else {
          item.show = true; // 其他 questionType 设为 true
        }
      });

      localStorage.setItem('result', JSON.stringify(arr))
      localStorage.setItem('applyData', JSON.stringify(this.applyData))
      localStorage.setItem('applyDatas', JSON.stringify(this.applyDatas))
      localStorage.setItem('assembly', JSON.stringify({
        code:0,
        data: {
          ...this.fullDetail,
          trademarkAssemblyInfo: this.applyData
        }
      }))
      this.$router.push({
        path: '/trademarkRejectionAI',
        query: {
          result: 1
        }
      })

      return;
      let list = []
      let lists = []
      this.requestTokens = []
      this.ifShowConfig = false
      const container = document.querySelector('.myAiGenerateContent');
      //commonSubstring
      if (this.applyData.trademarkCategory == 1) {
        if (this.applyData.trademarkNameCategory == 1) {
          if (this.applyDatas.filter(item => item.trademarkName.length >= 2).length == this.applyDatas.length) {
            this.commonSubstring = {
              flag: true,
              word: this.getCommonSubstring(this.applyDatas.map(item => item.trademarkName))
            }
          }
        } else {
          if (this.applyDatas.filter(item => item.trademarkName.length >= 3).length == this.applyDatas.length) {
            this.commonSubstring = {
              flag: true,
              word: this.getCommonSubstring(this.applyDatas.map(item => item.trademarkName))
            }
          }
        }
        if (this.commonSubstring.flag && this.commonSubstring.word) {
          this.similarTrademark()
        }
      }
      this.revoked()
      arr.sort((a, b) => a.age - b.age)
      arr.map(item => {
        list.push(this.messagesIdArr(item))
      })
      this.totalMessages = arr
      this.$nextTick(() => {
        this.renderPdf()
        this.serialNumber()
        container.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      })

      // 同时发送请求
      Promise.all(list)
        .then((results) => {
          // results 是一个数组，顺序与传入的 Promise 数组一致
          const [response1, response2, response3] = results;
          results.map((item, index) => {
            lists.push(this.getMessageList(item, index))
          })
          this.saveFlag = false
          this.$nextTick(() => {
            this.serialNumber()
          })
          Promise.all(lists)
            .then((res) => {
              this.$axios({
                method: 'get',
                url: '/trademark/reject/confirm/create/export/reject/word/' + this.fileChatNumber,
                headers: {'Content-Type': 'application/json'}
              })
              this.saveFlag = true
              this.buttonDisabled = false
              this.$nextTick(() => {
                this.serialNumber()
              })
            })
        })
        .catch((error) => {
          console.error('Error:', error);
        })
    },
    //<i class="serial1"></i>
    serialNumber() {
      // 定义中文数字数组
      const chineseNumbers = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
      // 获取所有具有 class="serial1" 的元素
      const elements = document.querySelectorAll(".serial1");
      const elementss = document.querySelectorAll(".serial2");
      // 遍历并赋值
      elements.forEach((el, index) => {
        el.textContent = chineseNumbers[index]; // 根据索引设置中文数字
      });
      elementss.forEach((el, index) => {
        el.textContent = chineseNumbers[index]; // 根据索引设置中文数字
      });
    },
    hasEmptyValue(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          // 判断值是否为 null、undefined、空字符串、空数组或空对象
          if (value === null || value === undefined || value === '' ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === 'object' && Object.keys(value).length === 0)) {
            return true;  // 如果有空值，返回 true
          }
        }
      }
      return false;  // 没有空值，返回 false
    },
    async generateReviewSave() {
      let flag = false
      //申请商标基础信息中的商标类型未选择
      if (!this.applyData.trademarkCategory) {
        this.$message({
          showClose: true,
          message: '请输入申请商标基础信息中的商标类型未选择',
          type: 'error'
        });
        return;
      }

      if (this.applyData.trademarkCategory != 2) {
        //申请商标基础信息中的商标类型未选择
        if (!this.applyData.trademarkNameCategory) {
          this.$message({
            showClose: true,
            message: '请输入申请商标基础信息中的商标名称类型未选择',
            type: 'error'
          });
          return;
        }

        if (!this.applyData.trademarkNameCategory == 1 || !this.applyData.trademarkNameCategory == 3) {

          if (!this.applyData.trademarkNameChineseNumber) {
            this.$message({
              showClose: true,
              message: '请输入申请商标基础信息中的汉字个数',
              type: 'error'
            });
            return;
          }

        }

        if (!this.applyData.trademarkNameCategory == 2 || !this.applyData.trademarkNameCategory == 3) {

          if (!this.applyData.englishLetterNumber) {
            this.$message({
              showClose: true,
              message: '请输入申请商标基础信息中的字母个数',
              type: 'error'
            });
            return;
          }

          if (!this.applyData.englishNameCategory) {
            this.$message({
              showClose: true,
              message: '请输入申请商标基础信息中的英文组成',
              type: 'error'
            });
            return;
          }

        }
      }

      this.gridOptions1.map((item, index) => {

        //申请号
        if (item.field == '申请号') {
          this.applyData.applyNo = item.value
          this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((items, indexs) => {
            items.applyNo = item['value' + (indexs + 1)]
            return items
          })
        }
        //拼音
        if (item.field == '拼音') {
          this.applyData.chinesePinYin = item.value
          this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((items, indexs) => {
            items.chinesePinYin = item['value' + (indexs + 1)]
            return items
          })
        }
        //拼音
        if (item.field == '与申请标近似部分（中文）') {
          this.applyData.similarPartChinese = item.value
          this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((items, indexs) => {
            items.similarPartChinese = item['value' + (indexs + 1)]
            return items
          })
        }
        if (item.field == '与申请标近似部分（英文）') {
          this.applyData.similarPartEnglish = item.value
          this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((items, indexs) => {
            items.similarPartEnglish = item['value' + (indexs + 1)]
            return items
          })
        }
        //采取措施
        if (item.field == '采取措施') {
          this.applyData.takeActionType = item.value
          this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((items, indexs) => {
            items.takeActionType = item['value' + (indexs + 1)]
            return items
          })
        }
        //商标名称近似性
        if (item.field == '商标名称近似性') {
          this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((items, indexs) => {
            items.trademarkSimilarDiscussionFlag = item['value' + (indexs + 1)]
            return items
          })
        }
        //商品服务近似性
        if (item.field == '商品服务近似性') {
          this.applyData.quoteTrademarkRejectList = this.applyData.quoteTrademarkRejectList.map((items, indexs) => {
            items.serviceSimilarDiscussionFlag = item['value' + (indexs + 1)]
            return items
          })
        }
      })
      if(!this.applyData.previousSimilarRejectList || (this.applyData.previousSimilarRejectList && this.applyData.previousSimilarRejectList.length == 0)) {
        this.applyData.previousSimilarBlag = false
      }
      let obj = {
        "fileChatNumber": this.fullDetail.fileChatNumber,
        "trademarkAssemblyInfo": JSON.stringify(this.applyData),
      }

      await this.delMessageId()

      if(!this.changeFlag) {
        this.save()
        return;
      }
      this.loadingText = "正在保存信息..."
      this.loading = true

      this.$axios.post(this.$route.query.assembly ? '/trademark/reject/confirm/update/trademark' : '/trademark/reject/confirm/update/trademark/again', obj).then(({data}) => {
        if (data.code == 0) {
          this.save()
        } else {
          this.$message(data.msg)
        }
        this.loading = false
      }).catch(err => {
        this.$message(err.data.msg)
        this.loading = false
      })
    },
    generateReview() {

      this.ifConvey = false
      let obj = null
      if (this.trademarkTitleActive == 0) {
        obj = {fileChatNumber: this.fileChatNumber, "uploadType": "1"}
      } else {
        if (this.applyTrademarkNo == null || this.applyTrademarkNo == '') {
          this.$message("请输入申请商标号")
          return;
        }

        if (this.finalRejection == null || this.finalRejection == '') {
          this.$message("请输入驳回意见")
          return;
        }

        obj = {
          applyNo: this.applyTrademarkNo,
          quoteApplyNoList: this.referenceTrademarkNo?.trim().replaceAll(" ", ";").replaceAll("，", ";").replaceAll(",", ";").replaceAll("；", ";").split(";"),
          rejectInformationList: this.finalRejection.split("\n"),
          fileChatNumber: this.fileChatNumber,
          uploadType: this.trademarkTitleActive == 0 ? 1 : 2
        }
      }
      this.loadingText = "正在提取商标信息中..."
      this.loading = true
      this.$axios.post('/trademark/reject/confirm/assembly', obj).then(({data}) => {
        this.loading = false
        if (data.code == 0) {
          this.panduan = this.trademarkTitleActive
          this.historyFlag = false
          this.fullDetail = data.data
          let obj = JSON.parse(data.data.trademarkAssemblyInfo)
          obj.rejectAssemblyInfo = JSON.parse(data.data.rejectAssemblyInfo)
          this.applyData = obj
          this.applyDatas = [{...obj}, ...obj.quoteTrademarkRejectList].map((item, index) => {
            if (index == 0) {
              item.name = '申请商标'
            } else {
              item.name = '引证商标' + (this.applyData.quoteTrademarkRejectList.length == 1 ? '' : index)
            }
            return item
          })
          this.cuchunApplyNoFlag = obj.applyNo
          this.columns = []
          this.columns = [
            {field: 'field', title: '', width: 130, fixed: 'left', showOverflow: false},
            //
            {
              field: 'value', title: '申请商标', editRender: {name: 'input'}, slots: {
                // 使用插槽模板渲染
                default: 'value',
              }, fixed: 'left', minWidth: 329, showOverflow: false
            },
          ]
          this.gridOptions1 = []
          obj.quoteTrademarkRejectList.map((item, index) => {
            // item.debateDimension = []
            this.columns.push({
              field: 'value' + (index + 1), title: '引证商标' + (this.applyDatas.length == 2 ? '' : index + 1), editRender: {name: 'input'}, slots: {
                // 使用插槽模板渲染
                default: 'value' + (index + 1),
              }, minWidth: 329, showOverflow: false
            })
            return item
          })
          let objs = null
          this.field.forEach((item, index) => {
            if(this.applyData.trademarkCategory == 1) {
              if(item.title == '商标图形') {
                return;
              }
            }
            if(this.applyData.trademarkCategory == 2) {
              if(item.title == '商标名称') {
                return;
              }
            }

            if(this.applyData.trademarkNameCategory == 1) {
              if(item.title == '与申请标近似部分（英文）') {
                return;
              }
            }

            if(this.applyData.trademarkNameCategory == 2) {
              if(item.title == '与申请标近似部分（中文）') {
                return;
              }
              if(item.title == '拼音') {
                return;
              }
            }
            objs = {}
            //第一列值
            objs.field = item.title
            this.columns.map((items, indexs) => {
              if (indexs == 1) {
                // 第二列值
                objs.value = obj[item.field]

              } else if (indexs > 1) {
                objs['value' + (indexs - 1)] = obj.quoteTrademarkRejectList[indexs - 2][item.field]
              }
            })

            this.gridOptions1.push({
              ...objs
            })
          })

          this.applyData.salientPartFlag = false
          this.applyData.previousSimilarBlag = false
        } else {
          this.$message(data.msg)
        }
        this.loading = false
        setTimeout(() => {
          this.changeFlag = false
        },1000)
        this.$nextTick(() => {
          this.changeFlag = false
        })
      }).catch(err => {
        this.$message(err.data.msg)
        this.loading = false
      })
    },
    replyArticleGenerate(ifRegenerate) {
      let url = "/trademark/reply/confirm/create/reply/word"

      if (!this.ifHistoryBackMessage) {
        let flag = false

        if (!this.fullDetail.trademarkCategory) {
          this.$message.error('商标类型必须选择');
          return
        }

        for (let tableDatum of this.tableData) {
          if (tableDatum.debateLevel === null) {
            this.$message.error(`请选择${tableDatum.name}的争辩力度`);
            flag = true
            break;
          }

          if ((tableDatum.debateLevel !== 0 && tableDatum.debateLevel !== null) && tableDatum.debateDimension.length == 0) {
            this.$message.error(`请选择${tableDatum.name}的争辩维度`);
            flag = true
            break;
          }
        }

        if (flag) {
          return
        }
      }

      if (ifRegenerate) {
        url = url + "/again"
      }

      this.ifGenerateFromFile = true
      this.ifShowLeftBlock = true
      this.ifShowRightBlock = true
      this.leftBlockSpan = 8
      this.middleBlockSpan = 8
      this.rightBlockSpan = 8

      this.renderPdf()

      this.loading = true
      this.loadingText = "复审申请文件生成中，请耐心等待..."
      this.ifShowConfig = false
      this.fakeHtmlData = ""
      this.fakeHtmlProgressNumber = 0
      this.fakeHtml = true
      this.buttonDisabled = true

      this.$nextTick(() => {
        if (this.panduan == 1) {
          this.handleRetractButton('left1')
        } else {
          this.handleRetractButton('left2')
        }
      })
      this.disabledFlag = 0
      this.ifShowEnd = true

      let data = this.ifHistoryBackMessage ? this.historyBackMessage : {
        ...this.fullDetail,
        applyNo: this.applyData.applyNo,
        quoteApplyNo: this.tableData.filter(item => item.name != '申请商标').map(item => item.applyNo).join(','),
        trademarkAssemblyInfo: JSON.stringify({
          "agencyAddress": this.applyData.agencyAddress,
          "agencyName": this.applyData.agencyName,
          "applicantAddress": this.applyData.applicantAddress,
          "applicantPersonName": this.applyData.applicantPersonName,
          "applyDate": this.applyData.applyDate,
          "applyNo": this.applyData.applyNo,
          "niceCode": this.applyData.niceCode,
          "niceCodeDetail": this.applyData.niceCodeDetail,
          "niceCodeName": this.applyData.niceCodeName,
          "rowIndex": this.applyData.rowIndex,
          "similarGroup": this.applyData.similarGroup,
          "similarGroupDetail": this.applyData.similarGroupDetail,
          "trademarkImageUrl": this.applyData.trademarkImageUrl,
          "trademarkName": this.applyData.trademarkName,
          "trademarkStatus": this.applyData.trademarkStatus,
          quoteTrademarkInfoList: this.tableData.filter(item => item.name != '申请商标').map(item => {
            item.debateDimension = item.debateDimension.sort()
            return item
          })
        })
      }

      this.$axios.post(url, data).then(response => {
        if (response.data.code === 10003) {
          window.clearTimeout(this.timer);
          this.$message.error(response.data.msg)
          this.ifShowConfig = true
          this.loading = false
          this.$refs.myTrademarkRejectUpload.uploadFiles = []
          return
        } else if (response.data.code === 501) {
          this.$message.error("很抱歉，申请号暂时识别不到，请您进行手动输入")
          this.ifShowConfig = true
          this.loading = false
          this.$refs.myTrademarkRejectUpload.uploadFiles = []
          return
        } else {
          this.queryData = response.data.data
          if (this.queryData.wordContextList != null) {
            this.handleQueryData(this.queryData.wordContextList)
          } else {
            this.$message(response.data.msg)
          }
        }
      })
        .catch(err => {
          if (this.$axios.isCancel(err)) {
            this.ifShowConfig = true
            this.loading = false
            this.$refs.myTrademarkRejectUpload.uploadFiles = []
            return
          }
          if (err.data && err.data.msg) {
            this.$message(err.data.msg)
          }
          this.ifShowConfig = true
          this.loading = false
          this.$refs.myTrademarkRejectUpload.uploadFiles = []
          this.buttonDisabled = false
        })
    },
    handleQueryData(data) {
      this.generateDomId2MessageId.clear()
      this.generateDomId2Item.clear()
      let aDiv = document.createElement("div")
      this.$nextTick(() => {
        data.forEach((item, index) => {
          if (item.chatFlag && item.wordText.contextText) {
            let aEditDiv = document.createElement("div")
            aEditDiv.id = 'editDiv' + index

            aEditDiv.style.fontSize = item.wordText.fontSize + "px"
            aEditDiv.style.lineHeight = 1.5
            aEditDiv.style.letterSpacing = '0.5px'
            aEditDiv.style.fontFamily = item.wordText.fontFamilyName
            aEditDiv.style.textAlign = item.wordText.alignment
            aEditDiv.style.fontWeight = item.wordText.boldFlag ? "bolder" : "normal"
            aEditDiv.style.textIndent = item.wordText.firstLineIndentedFlag ? "2ch" : "0"

            this.generateDomId2MessageId.set('editDiv' + index, item.wordText.contextText)
            this.generateDomId2Item.set('editDiv' + index, item)
            aDiv.appendChild(aEditDiv)
          } else {
            if (item.contextType == 0) {
              this.ganerateFileData = item
            } else if (item.contextType == 1) {
              let aP = document.createElement("p")
              aP.innerHTML = this.getInnerHTML(item.wordText.contextText)
              aP.style.fontSize = item.wordText.fontSize + "px"
              aP.style.lineHeight = 1.5
              aP.style.letterSpacing = '0.5px'
              aP.style.fontFamily = item.wordText.fontFamilyName
              aP.style.textAlign = item.wordText.alignment
              aP.style.fontWeight = item.wordText.boldFlag ? "bolder" : "normal"
              aP.style.textIndent = item.wordText.firstLineIndentedFlag ? "2ch" : "0"

              aP.style.padding = "10px"

              aP.id = 'p' + index
              this.generateDomId2Item.set(aP.id, item)

              aDiv.appendChild(aP)
            } else if (item.contextType == 2) {
              let aDiv2 = document.createElement("div")
              let aTable = document.createElement("table")
              aTable.style.margin = '0 auto'
              aTable.style.maxWidth = '700px'
              let nowRow = 0
              let listLength = item.wordTable.tableList.length

              for (let i = 0; i < listLength; i++) {
                let aTr = document.createElement("tr")
                let data = item.wordTable.tableList[i]
                for (let j = 0; j < data.length; j++) {
                  let dataItem = data[j]
                  let aTd = document.createElement("td")
                  if (j == 0) {
                    aTd.style.minWidth = '80px'
                  } else if (j == 1) {
                    aTd.style.minWidth = '100px'
                  }

                  aTd.innerHTML = this.getInnerHTML(dataItem.contextText)
                  aTd.style.fontSize = dataItem.fontSize + "px"
                  aTd.style.lineHeight = 1.5
                  aTd.style.letterSpacing = '0.5px'
                  aTd.style.fontFamily = dataItem.fontFamilyName
                  aTd.style.textAlign = dataItem.alignment
                  aTd.style.fontWeight = dataItem.boldFlag ? "bolder" : "normal"
                  aTd.style.textIndent = dataItem.firstLineIndentedFlag ? "2ch" : "0"

                  aTd.id = 'td' + index + i + j
                  this.generateDomId2Item.set(aTd.id, dataItem)

                  aTr.appendChild(aTd)
                }
                aTable.appendChild(aTr)
                nowRow++
              }

              aTable.style.padding = "10px"

              aDiv2.appendChild(aTable)
              aDiv.appendChild(aDiv2)
            } else {
              this.generateBrList.push(item)
              let aBr = document.createElement('br')
              aDiv.appendChild(aBr)
            }
          }
        })

        this.htmlData = aDiv.innerHTML

        this.fakeHtml = false
        this.loading = false

        this.handleGetMessageContents()
      })

    },
    handleGetMessageContents() {
      let generateDomId2MessageIdArray = []
      this.generateDomId2MessageId.forEach((value, key) => {
        generateDomId2MessageIdArray.push(key + "~~~" + value)
      })

      //递归调用获取信息
      this.handleGetMessageContentsForeach(generateDomId2MessageIdArray)
    },
    handleGetMessageContentsForeach(generateDomId2MessageIdList) {
      let that = this
      if (generateDomId2MessageIdList.length > 0) {
        let domId2MessageId1 = generateDomId2MessageIdList[0]
        let domId = domId2MessageId1.split("~~~")[0]
        let messageId = domId2MessageId1.split("~~~")[1]
        // 创建一个新的 CancelToken 实例
        this.stopSignal = axios.CancelToken.source();
        this.$axios.get(
          "/trademark/reject/chat/message/stream/" + messageId,
          {
            timeout: 10 * 60 * 10000,
            cancelToken: this.stopSignal.token, // 将 cancelToken 放在配置中
            onDownloadProgress: (event) => {
              this.flagw = true
              let content = event.target.response
              //换行
              let contentListNew = []
              let contentList = content.split("\n")
              contentList.forEach(item => {
                if (item != "") {
                  contentListNew.push("<p style='margin: 0'>" + item + "</p>")
                }
              })
              content = contentListNew.join("<br>")

              //加粗
              content = content.replaceAll("**", "%%%")
              let pattern = /%%%.*?%%%/g
              let arr = content.match(pattern)
              if (arr) {
                arr.forEach(item => {
                  let targetText = "<b>" + item.replaceAll("%%%", "") + "</b>"
                  content = content.replace(item, targetText)
                })
              }

              var objElement = document.createElement("div")
              objElement.innerHTML = that.htmlData

              let dom = objElement.querySelector("#" + domId)
              dom.innerHTML = content

              that.htmlData = objElement.innerHTML
            }
          }
        )
          .then(() => {
            this.flagw = false
            let nextList = generateDomId2MessageIdList.slice(1, generateDomId2MessageIdList.length)
            that.handleGetMessageContentsForeach(nextList)
          }).catch(err => {
          this.flagw = false
        })
      } else {
        this.buttonDisabled = false

        this.generateDownloadList = []
        this.generateDownloadList.push(this.ganerateFileData)
        let brNumber = 0
        var objElement = document.createElement("div")
        objElement.innerHTML = this.htmlData
        objElement.childNodes.forEach(item => {
          if (item.nodeName == "BR") {
            this.generateDownloadList.push(this.generateBrList[brNumber])
            brNumber++
          } else if (item.nodeName == "P") {
            let generateDownloadItem = {
              "contextType": 1,
              "wordText": {
                "contextText": this.getDownloadContextType(item.innerHTML),
                "fontSize": Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) > 0 ? Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) : 11,
                "fontFamilyName": item.style.fontFamily,
                "alignment": item.style.textAlign,
                "boldFlag": item.style.fontWeight == "bolder",
                "firstLineIndentedFlag": item.style.textIndent == "2ch",
                "headingStyle": item.id == '' ? "Normal" : this.generateDomId2Item.get(item.id).wordText.headingStyle
              },
              "wordTable": null
            }
            this.generateDownloadList.push(generateDownloadItem)
          } else if (item.nodeName == "DIV" && !item.id.startsWith("editDiv")) {
            let aTable, aTbody, trList = []
            item.childNodes.forEach(node => {
              if (node.nodeName == "TABLE") {
                aTable = node
              }
            })
            aTable.childNodes.forEach(node => {
              if (node.nodeName == "TBODY") {
                aTbody = node
              }
            })
            aTbody.childNodes.forEach(node => {
              if (node.nodeName == "TR") {
                trList.push(node)
              }
            })
            let rowLength = trList.length
            let colLength = 0
            trList[0].childNodes.forEach(node => {
              if (node.nodeName == "TD") {
                colLength++
              }
            })

            let generateDownloadItem = {
              "contextType": 2,
              "wordText": null,
              "wordTable": {
                "rowNumber": rowLength,
                "columnNumber": colLength,
                "tableList": []
              }
            }

            trList.forEach(aTr => {
              let itemList = []
              aTr.childNodes.forEach(aTd => {
                if (aTd.nodeName == "TD") {
                  let item = {
                    "contextText": this.getDownloadContextType(aTd.innerHTML),
                    "fontSize": Number(aTd.style.fontSize.slice(0, aTd.style.fontSize.length - 2)) > 0 ? Number(aTd.style.fontSize.slice(0, aTd.style.fontSize.length - 2)) : 11,
                    "fontFamilyName": aTd.style.fontFamily,
                    "alignment": aTd.style.textAlign,
                    "boldFlag": aTd.style.fontWeight == "bolder",
                    "firstLineIndentedFlag": aTd.style.textIndent == "2ch",
                    "headingStyle": aTd.id == '' ? "Normal" : this.generateDomId2Item.get(aTd.id).headingStyle
                  }
                  itemList.push(item)
                }
              })
              generateDownloadItem.wordTable.tableList.push(itemList)
            })
            this.generateDownloadList.push(generateDownloadItem)
          } else if (item.nodeName == "DIV" && item.id.startsWith("editDiv")) {
            let pList = item.querySelectorAll("p")
            if (pList.length > 0) {
              pList.forEach(it => {
                if (it.innerHTML != null && it.innerHTML != "") {
                  let generateDownloadItem = {
                    "contextType": 1,
                    "wordText": {
                      "contextText": it.innerHTML.replaceAll("<b>", "**").replaceAll("<b/>", "**").replaceAll("<br>", "\n"),
                      "fontSize": Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) > 0 ? Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) : 11,
                      "fontFamilyName": item.style.fontFamily,
                      "alignment": item.style.textAlign,
                      "boldFlag": item.style.fontWeight == "bolder",
                      "firstLineIndentedFlag": item.style.textIndent == "2ch",
                      "headingStyle": item.id == '' ? "Normal" : this.generateDomId2Item.get(item.id).wordText.headingStyle
                    },
                    "wordTable": null
                  }
                  this.generateDownloadList.push(generateDownloadItem)
                }
              })
            }
          }
        })

        this.queryData.wordContextList = this.generateDownloadList

        this.$axios({
          method: 'post',
          url: '/trademark/reply/confirm/save/wordAssemblyContext',
          data: this.queryData
        })
        this.ifShowEnd = false
      }
    },
    handleDownloadGenerates_(){
      this.$axios({
        method: 'get',
        url: '/trademark/reject/confirm/create/export/reject/word/' + this.fileChatNumber,
        headers: {'Content-Type': 'application/json'}
      })
        .then(res => {
          let result = res.data.data

          let prefix = window.location.origin
          let url = prefix + result.storageFilePath
          let fileName = result.storageFileName
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = url
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleDownloadGenerate() {
      this.generateDownloadList = []
      this.generateDownloadList.push(this.ganerateFileData)
      let brNumber = 0
      var objElement = document.createElement("div")
      objElement.innerHTML = this.htmlData
      objElement.childNodes.forEach(item => {
        if (item.nodeName == "BR") {
          this.generateDownloadList.push(this.generateBrList[brNumber])
          brNumber++
        } else if (item.nodeName == "P") {
          let generateDownloadItem = {
            "contextType": 1,
            "wordText": {
              "contextText": this.getDownloadContextType(item.innerHTML),
              "fontSize": Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) > 0 ? Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) : 11,
              "fontFamilyName": item.style.fontFamily,
              "alignment": item.style.textAlign,
              "boldFlag": item.style.fontWeight == "bolder",
              "firstLineIndentedFlag": item.style.textIndent == "2ch",
              "headingStyle": item.id == '' ? "Normal" : this.generateDomId2Item.get(item.id).wordText.headingStyle
            },
            "wordTable": null
          }
          this.generateDownloadList.push(generateDownloadItem)
        } else if (item.nodeName == "DIV" && !item.id.startsWith("editDiv")) {
          let aTable, aTbody, trList = []
          item.childNodes.forEach(node => {
            if (node.nodeName == "TABLE") {
              aTable = node
            }
          })
          aTable.childNodes.forEach(node => {
            if (node.nodeName == "TBODY") {
              aTbody = node
            }
          })
          aTbody.childNodes.forEach(node => {
            if (node.nodeName == "TR") {
              trList.push(node)
            }
          })
          let rowLength = trList.length
          let colLength = 0
          trList[0].childNodes.forEach(node => {
            if (node.nodeName == "TD") {
              colLength++
            }
          })

          let generateDownloadItem = {
            "contextType": 2,
            "wordText": null,
            "wordTable": {
              "rowNumber": rowLength,
              "columnNumber": colLength,
              "tableList": []
            }
          }

          trList.forEach(aTr => {
            let itemList = []
            aTr.childNodes.forEach(aTd => {
              if (aTd.nodeName == "TD") {
                let item = {
                  "contextText": this.getDownloadContextType(aTd.innerHTML),
                  "fontSize": Number(aTd.style.fontSize.slice(0, aTd.style.fontSize.length - 2)) > 0 ? Number(aTd.style.fontSize.slice(0, aTd.style.fontSize.length - 2)) : 11,
                  "fontFamilyName": aTd.style.fontFamily,
                  "alignment": aTd.style.textAlign,
                  "boldFlag": aTd.style.fontWeight == "bolder",
                  "firstLineIndentedFlag": aTd.style.textIndent == "2ch",
                  "headingStyle": aTd.id == '' ? "Normal" : this.generateDomId2Item.get(aTd.id).headingStyle
                }
                itemList.push(item)
              }
            })
            generateDownloadItem.wordTable.tableList.push(itemList)
          })
          this.generateDownloadList.push(generateDownloadItem)
        } else if (item.nodeName == "DIV" && item.id.startsWith("editDiv")) {
          let pList = item.querySelectorAll("p")
          if (pList.length > 0) {
            pList.forEach(it => {
              if (it.innerHTML != null && it.innerHTML != "") {
                let generateDownloadItem = {
                  "contextType": 1,
                  "wordText": {
                    "contextText": it.innerHTML.replaceAll("<b>", "**").replaceAll("<b/>", "**").replaceAll("<br>", "\n"),
                    "fontSize": Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) > 0 ? Number(item.style.fontSize.slice(0, item.style.fontSize.length - 2)) : 11,
                    "fontFamilyName": item.style.fontFamily,
                    "alignment": item.style.textAlign,
                    "boldFlag": item.style.fontWeight == "bolder",
                    "firstLineIndentedFlag": item.style.textIndent == "2ch",
                    "headingStyle": item.id == '' ? "Normal" : this.generateDomId2Item.get(item.id).wordText.headingStyle
                  },
                  "wordTable": null
                }
                this.generateDownloadList.push(generateDownloadItem)
              }
            })
          }
        }
      })

      this.loading = true
      this.loadingText = "文件导出中，请稍候..."
      this.queryData.wordContextList = this.generateDownloadList

      this.$axios({
        method: 'post',
        url: '/trademark/reply/confirm/export/reply/word',
        headers: {'Content-Type': 'application/json'},
        data: this.queryData
      })
        .then(res => {
          this.historyDownloads(res.data.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    //查询商标复审历史数据
    queryHistory_() {
      let param = {
        current: this.reviewHistoryQueryParam.pageNum,
        size: this.reviewHistoryQueryParam.pageSize,
        orderType: 'desc',
        orderField: 'createTime'
      }
      this.$axios({
        method: 'get',
        url: '/trademark/reject/confirm/history/list/page',
        params: param
      }).then(response => {
        this.reviewHistoryList = response.data.data.records
        this.reviewHistoryTotal = response.data.data.total
      })
    },
    handleTrademarkHistorySizeChanges(val) {
      this.trademarkHistoryQueryParam.pageSize = val
      this.queryHistory_()
    },
    handleTrademarkHistoryCurrentChanges(val) {
      this.trademarkHistoryQueryParam.pageNum = val
      this.queryHistory_()
    },
    handleSendMessages(item) {
      this.$axios.post("/trademark/reject/chat/message/create", {
        fileChatNumber: this.fileChatNumber,
        // 驳回问题类型：0通用会话,10整体外观方面，20显著部分，30呼叫发音，40商标含义，50商品服务，60生产部门，70销售渠道，80消费对象
        questionType: 0,
        content: this.myAIDialogInput
      })
        .then(response => {
          let myConversationId = response.data.data

          this.dialogList.push({
            type: 'user',
            conversationId: myConversationId,
            content: this.myAIDialogInput
          })
          this.dialogList.push({
            type: 'assistant',
            conversationId: myConversationId,
            content: ""
          })

          this.myAIDialogInput = ""
          this.handleScrollToButtom('AIDialogueContent')
          this.myAIDialogInput = ""

          this.handleGetResponseMessages(myConversationId)
        })
    },
    handleGetResponseMessages(conversationId) {
      // 创建一个新的 CancelToken 实例
      this.stopSignal2 = axios.CancelToken.source();
      this.$axios.get(
        "/trademark/reject/chat/message/stream/" + conversationId,
        {
          timeout: 10 * 60 * 10000,
          cancelToken: this.stopSignal2.token, // 将 cancelToken 放在配置中
          onDownloadProgress: (event) => {
            this.chatLoading = true
            //换行
            let content = event.target.response.replaceAll("\n", "<br>")
            //加粗
            content = content.replaceAll("**", "%%%")
            let pattern = /%%%.*?%%%/g
            let arr = content.match(pattern)
            if (arr) {
              arr.forEach(item => {
                let targetText = "<b>" + item.replaceAll("%%%", "") + "</b>"
                content = content.replace(item, targetText)
              })
            }
            //覆盖
            this.dialogList.forEach(myItem => {
              if (myItem.conversationId == conversationId && myItem.type == 'assistant') {
                myItem.content = content
              }
            })
            //滚动
            this.handleScrollToButtom("AIDialogueContent")
          }
        }
      )
        .then(() => {
          this.chatLoading = false

        })
        .catch(err => {
          this.chatLoading = false
        })
    },

    getFormattedTime(time) {
      let date = new Date(time)
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      let h = date.getHours()
      h = h < 10 ? ('0' + h) : h
      let M = date.getMinutes()
      M = M < 10 ? ('0' + M) : M
      let s = date.getSeconds()
      s = s < 10 ? ('0' + s) : s
      let dateTime = y + '-' + m + '-' + d + ' ' + h + ':' + M + ':' + s;

      return dateTime
    },
    handleCopy(item) {
      let stringContent = item.content.replaceAll('<b>', "").replaceAll("</b>", "").replaceAll("<br>", "\n\n");
      //获取要赋值的input的元素
      var inputElement = document.getElementById("copy_content");
      //给input框赋值
      inputElement.value = stringContent
      //选中input框的内容
      inputElement.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //提示已复制
      this.$message('已复制');
    },
    handleRegenerate(item) {
      this.dialogList.forEach(myItem => {
        if (myItem.conversationId == item.conversationId && myItem.type == 'assistant') {
          myItem.content == ""
        }
      })


      if (this.ifConvey) {
        this.handleGetResponseMessage(item.conversationId)
      } else {
        this.handleGetResponseMessages(item.conversationId)
      }
    },

    skip(item) {
      if (item) {
        this.$router.push({path: item})
      } else {
        location.reload();
      }
    },
    handleScrollToButtom(domId) {
      this.$nextTick(() => {
        let dom = document.getElementById(domId)
        dom.scrollTop = dom.scrollHeight - dom.clientHeight
      })
    },
    handleRetractButton(type) {
      if (this.trademarkTitleActive == 1) {
        if (type == 'left2') {
          return;
        }
      }

      switch (type) {
        case 'left1':
          this.ifShowLeftBlock = false
          break;
        case 'left2':
          this.ifShowLeftBlock = true
          break;
        case 'right1':
          this.ifShowRightBlock = false
          break;
        case 'right2':
          this.ifShowRightBlock = true
          break;
        default:
          break
      }

      if (this.ifGenerateFromFile) {
        if (this.ifShowLeftBlock && this.ifShowRightBlock) {
          this.leftBlockSpan = 8
          this.middleBlockSpan = 8
          this.rightBlockSpan = 8
        } else if (!this.ifShowLeftBlock && !this.ifShowRightBlock) {
          this.leftBlockSpan = 0
          this.middleBlockSpan = 24
          this.rightBlockSpan = 0
        } else if (this.ifShowLeftBlock) {
          this.leftBlockSpan = 12
          this.middleBlockSpan = 12
          this.rightBlockSpan = 0
        } else {
          this.leftBlockSpan = 0
          this.middleBlockSpan = 12
          this.rightBlockSpan = 12
        }
      } else {
        if (this.ifShowRightBlock) {
          this.leftBlockSpan = 0
          this.middleBlockSpan = 12
          this.rightBlockSpan = 12
        } else {
          this.leftBlockSpan = 0
          this.middleBlockSpan = 24
          this.rightBlockSpan = 0
        }
      }

      this.generateDomId2MessageId.forEach((value, key) => {
        let dom = document.getElementById(key)
        dom.style.height = "0px"
        this.$nextTick(() => {
          dom.style.height = dom.scrollHeight - 10 + "px"
        })
      })
    },
    //创建右侧对话
    createChat() {
      this.$axios.post("/trademark/reply/chat/create", {fileChatNumber: this.fileChatNumber})
        .then(response => {
          if (response.data.code != 0) {
            this.$message.error("创建右侧对话失败")
            return
          }
          this.chatNumber = response.data.data
        })
    },
    getDownloadContextType(text) {
      // 匹配 span 标签并替换成自定义标记，支持单引号和双引号的 color 属性
      const patternSpan = /<span style=['"]color=#([a-fA-F0-9]{6})['"]>(.*?)<\/span>/g;
      text = text.replace(patternSpan, (match, color, content) => {
        return `**textColor-${color}**${content}**textColor-${color}**`;
      });

      // 匹配 img 标签并替换成自定义标记
      const patternImg = /<img [^>]*?src="(.*?)"[^>]*?>/g;
      text = text.replace(patternImg, (match, src) => {
        return `**img**${src}**img**`;
      });

      // 转换 HTML 实体
      text = text.replace(/<br>/g, "\n");
      text = text.replace(/&lt;/g, "<");
      text = text.replace(/&gt;/g, ">");
      text = text.replace(/&nbsp;/g, " ");

      return text;
    },
  },
}
</script>

<style lang="scss" >

  .vxe_table {
    border-radius: 8px 8px 4px 4px;
    overflow: hidden;

    .el-checkbox-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      label {
        margin-bottom: 5px;
        margin-right: 10px;
        .el-checkbox__label {
          width: 60px;
        }
      }
    }

    .el-radio-group {
      display: flex;
      justify-content: center!important;
      flex-wrap: wrap!important;
      flex-direction: row!important;
      //justify-content: space-between;
      .el-radio {
        margin-right: 0;
        &:nth-of-type(1) {
          margin-right: 40px!important;
        }
        &:nth-of-type(3) {
          margin-right: 40px!important;
        }
        &.is-disabled {
          .el-radio__label {
            color: #555555!important;
          }
        }
      }

      &.active {
        .el-radio {
          margin-right: 10px!important;
        }
      }

      label {
        margin-top: 2px;
        margin-bottom: 2px;
      }
    }

    .col-applyNo {
      color: rgb(100, 131, 237)!important;
    }

    .imgBox {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      height: 46px;
      padding: 5px;
      box-sizing: border-box;
      background: #FFFFFF;
      box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.12);
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #7C93C6;
    }

    .col-niceCode {
      font-weight: 500;
      font-size: 12px;
      color: #464F60;
    }

    .col-trademarkName {
      font-weight: 500;
      font-size: 12px;
      color: #171C26;
    }

    .col-name {
      font-weight: 600;
      font-size: 12px;
      color: #333333;
      border-right: 1px solid #E9EDF5!important;
    }

    .row--stripe {
      background: #EBF0FA!important;
    }

    // 滚动条的宽度
    .vxe-table--body-wrapper::-webkit-scrollbar {
      width: 10px; // 横向滚动条
      height: 10px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    .vxe-table--body-wrapper::-webkit-scrollbar-thumb {
      background: #708DB8;
      border-radius: 10px
    }

    .vxe-table--body-wrapper::-webkit-scrollbar-track {
      background: hsla(0, 0%, 53%, .1)
    }

    .fixedHeader {
      background: #D3DBF8;
      font-weight: 500;
      font-size: 14px;
      color: #555555;
    }

    .moveHeader {
      background: rgba(211,219,248,0.5553);
      font-weight: 500;
      font-size: 14px;
      color: #555555;
    }

    .cellClassNameFixed {
      font-weight: 400;
      font-size: 12px;
      color: #555555;
      background: white;
    }

    .cellClassNameMove {
      font-weight: 400;
      font-size: 12px;
      color: #555555;
      background: rgba(233,237,245,0.2195);
    }
  }
  .reviewDetails {
    background: #F3F5FA;
    .home {
      height: 90px;
    }
    .reviewDetails_cen {
      margin: 0 auto;
      width: 1200px;
      .danTitles {
        margin-top: 17px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #999999;
        font-weight: 600;
        position: relative;
        span {
          margin: 0 9px;
          &:nth-of-type(1) {
            margin-left: 18px;
          }
          &.active {
            color: #506bce;
          }
        }
        span:hover {
          cursor: pointer;
          color: #506bce;
        }
      }
      .box {
        padding: 21px 33px;
        box-sizing: border-box;
        margin-top: 15px;
        width: 100%;
        height: 270px;
        background: #FFFFFF;
        box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.15);
        border-radius: 15px 15px 15px 15px;
        .significantParts {
          padding-left: 10px;
          .title {
            margin: 11px 0 7px;
            font-weight: 500;
            font-size: 14px;
            color: #333333;
          }
          .significantParts_item {
            padding: 16px 22px;
            box-sizing: border-box;
            width: 100%;
            height: 100px;
            background: #EBF0FA;
            border-radius: 3px 3px 3px 3px;
            .item {
              font-weight: 400;
              font-size: 12px;
              color: #000000;
              margin-bottom: 9px;
            }
          }
          .significantParts_item_ {
            width: 749px;
            height: 49px;
            background: #EBF0FA;
            border-radius: 3px 3px 3px 3px;
            padding: 9px 22px;
            box-sizing: border-box;
            .item {
              font-weight: 400;
              font-size: 12px;
              color: #000000;
              margin-bottom: 9px;
              margin-right: 56px;
            }
          }
          .table_ {
            margin-top: 16px;
            border-radius: 15px 15px 0 0;
            /*overflow: hidden;*/
            font-size: 14px;
            text-align: left;
            td {
              font-size: 14px;
              text-align: left;
            }
            .table_header {
              th {
                background: #D3DBF8!important;
                border: none!important;
                font-weight: 500;
                font-size: 14px;
                color: #555555;
              }
            }
          }
        }
        .line {
          width: 100%;
          height: 2px;
          background: #C6D3F2;
        }
        .titles {
          margin-top: 15px;
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          padding-left: 9px;
          margin-bottom: 12px;
          position: relative;
          &:before {
            left: 0;
            top: 4px;
            content: '';
            display: block;
            width: 3px;
            height: 13px;
            position: absolute;
            background: #2B68D4;
            border-radius: 0px 0px 0px 0px;
          }
        }
        .trademarkInformation {
          box-sizing: border-box;
          height: 126px;
          border-radius: 8px 8px 8px 8px;
          border: 2px solid #C3CFEB;
          overflow: hidden;
          ._li {
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-radio-group {
              display: flex;
              flex-wrap: wrap;
              .is-disabled {
                .el-radio__label {
                  color: #555555!important;
                }
              }
            }
            .radio-group {
              .el-radio {
                display: flex;
                align-items: center;
                margin-right: 30px;
              }
            }
            .name {
              margin-right: 30px;
              display: flex!important;
              align-items: center;
              justify-content: center;
              width: 141px!important;
              height: 42px!important;
              background: #EBF0FA;
              border-right: 2px solid #C3CFEB;
              border-left: 2px solid #C3CFEB;
              font-weight: 400;
              font-size: 14px;
              color: #000000;
              &.active {
                border-left: 0px solid #C3CFEB;
              }
            }
            p:nth-of-type(1) {
              width: 50%;
              display: flex;
              align-items: center;
              span:nth-of-type(1) {
                display: block;
                //width: 80px;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
              }

              span:nth-of-type(2) {
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                img {
                  max-width: 160px;
                  max-height: 67px;
                }
              }
            }

            p:nth-of-type(2) {
              width: 50%;
              display: flex;
              align-items: center;
              .span_:nth-of-type(1) {
                display: block;
                width: 80px;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
              }

              span:nth-of-type(2) {
                display: block;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                &.sheng {
                  margin-top: -3px;
                  overflow: hidden;
                  line-height: 1.5;       /* 行高 */
                  display: block;
                  display: -webkit-box;             /* 必须使用 flexbox 模式 */
                  -webkit-line-clamp: 3;            /* 限制显示的行数 */
                  -webkit-box-orient: vertical;
                  text-overflow: ellipsis;
                  word-break: break-word; /* 防止单词溢出 */
                }
              }
            }

            .imgBox {
              max-width: 160px;
              padding: 5px;
              display: flex!important;
              align-items: center;
              justify-content: center;
              background: #FFFFFF;
              overflow: hidden;
              box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.12);
              border-radius: 8px 8px 8px 8px;
              border: 1px solid #7C93C6;
            }

            &:nth-of-type(1) {
              position: relative;
              &:before {
                display: block;
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background: #C3CFEB;
                bottom: 0px;
                left: 0px;
              }
            }
            &:nth-of-type(2) {
              position: relative;
              &:before {
                display: block;
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background: #C3CFEB;
                bottom: 0px;
                left: 0px;
              }
            }
          }
        }
      }
      .generateClass {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 200px;
        height: 40px;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        background: linear-gradient( 252deg, #2E72D0 0%, #1B427A 100%);
        border-radius: 8px 8px 8px 8px;
      }

      .el-dialog{
        margin-top: 4vh!important;
        height: 90vh;
      }

      #copy_content{
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -10;
      }

      .TrademarkRejectionAi{
        margin-top: 95px;

        .trademarkRejection{
          position: relative;
          margin: 10px 20px;
          color: #000000;
          height: 83vh;
          border: 1px solid rgba(7, 13, 67, .18);
          border-radius: 15px;
          background-color: #ffffff;
          box-shadow: 0 0 4px 0 rgba(7,13,67,0.18);

          .history {
            position: absolute;
            right: -4px;
            top: 76px;
            width: 73px;
            height: 48px;
            z-index: 99;
            cursor: pointer;
            background-color: transparent;
          }

          .trademarkConfigTitle{
            margin: 10px 0 0 40px;
            .switchTab {
              margin-top: 12px;
              display: flex;
              padding-left: 11px;
              box-sizing: border-box;
              span {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 110px;
                height: 35px;
                font-size: 14px;
                background: #E0E4EB;
                color: #666666;
                border-radius: 10px 10px 0 0;
                &:nth-of-type(1){
                  margin-right: 4px;
                }
                &.active {
                  color: #1B427A;
                  background: #B0C2F2;
                }
              }
            }
          }

          .trademarkConfig{

            .trademarkConfigContent{
              padding: 10px;
              background: #EDF2FD;
              height: 73vh;
              overflow: hidden;
              border-radius: 10px 0 0 10px;

              .trademarkConfigContentTitle{
                h3{
                  position: relative;
                  margin: 20px 0 0 40px;
                  font-size: 16px;
                  letter-spacing: .5px;
                }
                h3:before{
                  content: "";
                  position: absolute;
                  top: 5px;
                  left: -15px;
                  height: 15px;
                  width: 4px;
                  background-color: #2B68D4;
                }
                p{
                  margin-left: 40px;
                  font-size: 12px;
                  letter-spacing: .5px;
                  color: red;
                }
              }

              .trademarkSwitch{
                display: inline-block;
                margin: 20px 0px 20px 60px;
                padding: 0 5px;
                background-color: #CED9F2;
                border-radius: 8px;

                .trademarkSwitchTitle{
                  display: inline-block;
                  padding: 12px 26px;
                  margin: 0;
                  font-size: 13px;
                  border-radius: 8px;
                  cursor: pointer;
                  background-color: #CED9F2;
                  color: #666666;
                  font-weight: bold;
                }
                .trademarkSwitchTitle:first-of-type{
                  margin-right: 2px;
                }
                .trademarkActive{
                  color: #2B68D4;
                  background-color: #ffffff;
                  box-shadow: 0 0 4px 0 #aaaaaa;
                  padding: 8px 24px;
                  font-weight: bold;
                }
              }

              .trademarkUpload{
                height: 252px;
                position: relative;

                .trademarkRejectionAiUpload{
                  margin-top: 10px;
                  margin-left: 60px;
                }
                .el-upload-dragger{
                  height: 180px;
                  width: 450px;
                  padding-top: 20px;
                  border: 2px dashed #4178C2;
                }
              }
              .trademarkUpload:after{
                position: absolute;
                content: "";
                bottom: 0;
                left: 40px;
                //background-color: #B8D0F0;
                height: 1px;
                width: 630px;
              }

              .trademarkInput{
                position: relative;
                padding-bottom: 22px;

                h3{
                  margin-left: 60px;
                  margin-bottom: 30px;
                  color: #333333;
                  font-size: 16px;
                  font-weight: bolder;
                }
                .trademarkInputItem{
                  margin: 20px 0 20px 70px;
                  p{
                    display: inline-block;
                    margin-right: 20px;
                    width: 70px;
                    font-size: 14px;
                    vertical-align: middle;
                    text-align: right;
                    font-weight: bold;
                  }
                  .myTrademarkInput{
                    display: inline-block;
                    width: 300px;
                    vertical-align: middle;
                  }

                  .el-input__inner{
                    border: 1px solid transparent!important;
                  }

                  .el-textarea__inner{
                    padding: 8px 20px;
                    font-size: 13px;
                  }
                }
              }
              .trademarkInput:after{
                position: absolute;
                content: "";
                bottom: 0;
                left: 40px;
                /*background-color: #B8D0F0;*/
                height: 1px;
                width: 630px;
              }

              .trademarkType{
                margin-top: 20px;
                h3{
                  margin-left: 60px;
                  margin-bottom: 15px;
                  color: #333333;
                  font-size: 14px;
                  font-weight: bold;
                }
                .trademarkContent{
                  font-size: 14px;
                  margin-left: 20px;
                  font-weight: bold;
                }
                .trademarkContent:first-of-type{
                  margin-left: 60px;
                }
              }

              .trademarkButton{
                margin: 90px 0 0 90px;

                .trademarkButtonItem{
                  color: #FFFFFF;
                  font-size: 13px;
                  letter-spacing: .3px;
                  width: 226px;
                  height: 58px;
                  background: #1758B4;
                  border-radius: 8px 8px 8px 8px;
                  &:nth-of-type(1) {
                    margin-bottom: 40px;
                  }
                  &.active {
                    background: #BEC6D1;
                    color: #FFFFFF;
                  }
                }
              }
            }
          }
          .trademarkInformationReport {
            // 滚动条的宽度
            &::-webkit-scrollbar {
              width: 10px; // 横向滚动条
              height: 10px; // 纵向滚动条 必写
            }

            // 滚动条的滑块
            &::-webkit-scrollbar-thumb {
              background: hsla(0, 0%, 53%, .1);
              border-radius: 10px
            }

            &::-webkit-scrollbar-track {
              background: hsla(0, 0%, 53%, .1)
            }



            border-radius: 0 10px 10px 0;
            background: #CED9F2;
            padding: 25px 41px 25px 34px;
            height: 75.2vh;
            box-sizing: border-box;
            //overflow: hidden;
            overflow-y: auto;


            .title {
              width: 452px;
              height: 45px;
              padding-left: 18px;
              box-sizing: border-box;
              background: #FFFFFF;
              border-radius: 5px 5px 5px 5px;
              display: flex;
              align-items: center;
              font-weight: 500;
              font-size: 14px;
              color: #000000;
              span {
                font-size: 35px;
                margin-top: 16px;
                color: #E35656;
              }
            }



            .generateClass {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              width: 200px;
              height: 40px;
              font-weight: 500;
              font-size: 16px;
              color: #FFFFFF;
              background: linear-gradient( 252deg, #2E72D0 0%, #1B427A 100%);
              border-radius: 8px 8px 8px 8px;
            }

            .titless {
              margin-top: 5px;
              font-weight: 400;
              font-size: 14px;
              color: #000000;
              position: relative;
              padding-left: 9px;
              margin-bottom: 5px;
              &:before {
                left: 0;
                top: 4px;
                content: '';
                display: block;
                width: 3px;
                height: 13px;
                position: absolute;
                background: #2B68D4;
                border-radius: 0px 0px 0px 0px;
              }
            }
          }

          .trademarkHistory{
            display: flex;
            flex-direction: column;
            background: #EDF2FD;
            height: 75vh;
            border-radius: 10px;

            .historyFlagClass {
              width: 86%;
            }

            h2{
              vertical-align: middle;
              margin: 20px 20px 10px;
              padding-bottom: 15px;
              color: #555555;
              font-size: 14px;
              font-weight: bolder;
              border-bottom: 1px solid #CFD5EB;

              svg{
                margin-left: 20px;
                vertical-align: middle;
              }
              span{
                margin-left: 10px;
              }
            }

            .trademarkHistoryList{
              height: 55vh;
              overflow-y: scroll;
              scrollbar-width: none;

              .trademarkHistoryItem{
                padding: 4px 20px;
                position: relative;

                h3{
                  font-weight: normal;
                  font-size: 16px;
                  margin: 5px 0;
                  padding-right: 30px;
                }
                p{
                  font-size: 12px;
                }
                .rightHistoryDownloadIcon{
                  position: absolute;
                  width:22px;
                  height:20px;
                  top: calc( 50% - 10px );
                  right: 0px;
                  cursor: pointer;
                  color: #3567E6;
                  background-color: rgb(236, 240, 251);
                }
                .rightHistoryBackReviewIcon{
                  position: absolute;
                  width:22px;
                  height:20px;
                  top: calc( 50% - 10px );
                  right: 25px;
                  cursor: pointer;
                  color: #3567E6;
                  background-color: rgb(236, 240, 251);
                }
              }
            }

            .nonHostory{
              display: flex;
              align-items: center;
              justify-content: center;
              height: 54vh;
            }
          }
        }

        .el-pagination__jump{
          margin-left: 0;
        }
      }

      .trademarkRejectionResult{
        text-align: center;
        display: inline-block;
        width: 99%;
        margin: 95px auto 5px;
        padding-top: 5px;
        padding-bottom: 3vh;
        background-image:-webkit-linear-gradient( 42deg, #E1E7F8 0%, #D4DFFC 54%, #97BAE7 100%);
        border-radius: 8px 8px 8px 8px;

        .danTitle {
          margin-left: 0;
        }

        .trademarkRejectionGenerateMainBlock{
          vertical-align: top;
          display: inline-block;
          width: 90vw;

          .mainContentBlock{
            position: relative;
            height: calc( 80vh + 5px );
            background: rgba(255,255,255,0.4);
            border-radius: 15px 15px 15px 15px;
            border: 2px solid #ffffff;
            box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.08);

            .myPdfPreviewBlockOutline{
              height: calc( 100% - 60px );
              margin: 30px 0;
              overflow-y: scroll;
              scrollbar-width:none;

              .myPdfPreviewBlock{
                padding: 0 30px;
              }
            }

            .mainContentBlockTitle{
              position: absolute;
              top: -30px;
              left: 30px;

              font-family: Inter, Inter;
              font-weight: bold;
              font-size: 18px;
              color: #172A6B;
              line-height: 21px;

              .resultContent{
                background-color: #EDF2FD;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: right bottom;
                margin-top: 10px;


              }
            }

            .myFullScreen{
              position: absolute;
              top: 8px;
              right: 8px;
              cursor: pointer;
            }

            .AIGenerateContent{
              height: calc( 72vh - 40px );
              padding: 20px;
              text-align: left;

              .myAiGenerateContent{
                overflow-y: scroll;
                scrollbar-width:none;
                height: 100%;
                padding-right: 1px;
                .container {
                  .table_ {
                    width: 100%;
                    border-collapse: collapse;
                    text-align: center;
                    td, th {
                      border: 1px solid #000;
                      padding: 10px;
                    }
                  }

                  .section1 {
                    margin-top: 5px;
                    p {
                      margin: 3px 0;
                      font-size: 14px;
                      span:nth-of-type(1) {
                        margin-right: 5px;
                      }
                    }
                  }
                  .section {
                    margin-top: 10px;
                    h5 {
                      font-size: 14px;
                      margin: 5px 0;
                    }
                    p {
                      margin: 3px 0;
                      font-size: 14px;
                      text-indent: 2em; /* 2em 的首行缩进 */
                    }

                    .mavonEditor_1 {
                      .content-input-wrapper {
                        box-sizing: border-box;
                        padding: 10px!important;
                      }
                    }
                  }
                }
              }
            }
            .AIGenerateBottomButton{
              padding-top: 20px;
              height: calc( 8vh - 18px );
              background: rgba(255,255,255,0.4);
              border-bottom-right-radius: 15px;
              border-bottom-left-radius: 15px;
              .AIGenerateButton2{
                font-weight: 500;
                font-size: 14px;
                color: #666666;
                display: inline-block;
                padding: 10px;
                border-radius: 8px 8px 8px 8px;
              }
              .AIGenerateButton{
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
                display: inline-block;
                padding: 10px 30px;
                background-image:-webkit-linear-gradient( 252deg, #2E72D0 0%, #1B427A 100%);
                border-radius: 8px 8px 8px 8px;
              }
            }

            .AIDialogTitle{
              position: absolute;
              top: -30px;
              left: 30px;

              -webkit-background-clip:text;
              -webkit-text-fill-color:transparent;
              background-image:-webkit-linear-gradient(358.02892004842deg, #0A2A64 0%, #1C5FBC 100%);
              font-family: Inter, Inter;
              font-weight: bold;
              font-size: 18px;
              line-height: 21px;
            }
            .AIDialogueContent{
              margin: 20px 20px 0;
              width: calc( 100% - 40px );
              height: calc( 100% - 160px );

              overflow-y: scroll;
              scrollbar-width: none;

              .assistantDialog{
                text-align: left;
                padding: 30px 0;
                position: relative;

                .dialogIconAssistant{
                  display: inline-block;
                  vertical-align: bottom;
                  height: 34px;
                  width: 34px;
                }
                .dialogContentAssistant{
                  display: inline-block;
                  vertical-align: bottom;
                  padding: 10px;
                  margin-left: 10px;
                  background-color: #FFFFFF;
                  border-radius: 8px;
                  min-height: 18px;

                  font-family: Roboto, Roboto;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                }

                .dialogIconGroup{
                  display: none;
                  position: absolute;
                  bottom: 5px;
                  left: 40px;

                  .AIDialogueCopyIcon{
                    color: #555555;
                    margin-left: 5px;
                    vertical-align: bottom;
                    display: inline-block;
                    cursor: pointer;
                  }
                }
              }
              .assistantDialog:hover{
                .dialogIconGroup{
                  display: inline-block;
                }
              }
              .userDialog{
                text-align: right;
                margin: 20px 0;

                .dialogContentUser{
                  display: inline-block;
                  vertical-align: bottom;
                  margin-right: 10px;
                  padding: 10px;
                  text-align: left;
                  max-width: 70%;
                  background-color: #A2C1FF;
                  border-radius: 8px;

                  font-family: Roboto, Roboto;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                }
                .dialogIconUser{
                  display: inline-block;
                  vertical-align: bottom;
                  height: 34px;
                  width: 34px;
                }
              }

              .myChatStopGenerate{
                font-weight: 500;
                font-size: 14px;
                display: inline-block;
                padding: 8px 15px;
                border-radius: 8px 8px 8px 8px;
                span{
                  margin-left: 5px;
                  vertical-align: middle;
                }
                svg{
                  vertical-align: middle;
                }
              }
            }
            .AIDialogueInput{
              height: 80px;
              padding: 20px 20px;
              position: relative;

              .AIDialogInputBlock{
                width: calc( 100% - 70px );
                vertical-align: middle;
              }

              .AIDialogInputBlock ::v-deep .el-textarea__inner{
                scrollbar-width: none;
              }

              .myChatStopGenerate_box {
                position: absolute;
                top: -40px;
                left: calc( (100% - 40px)/2 - 36px );
              }

              .AIDialogInputButton{
                display: inline-block;
                vertical-align: middle;
                height: 40px;
                width: 40px;
                margin: 10px 0 10px 20px;
                cursor: pointer;
              }
            }

            .leftButton1{
              position: absolute;
              height: 36px;
              width: 36px;
              left: -18px;
              top: calc( 50% - 18px );
              z-index: 5;
              cursor: pointer;
            }
            .leftButton2{
              position: absolute;
              left: -26px;
              top: calc( 50% - 19px );
              height: 38px;
              width: 24px;
              background-color: #6C80B3;
              color: #ffffff;
              line-height: 38px;
              font-family: Inter, Inter;
              font-weight: bold;
              font-size: 12px;
              border-radius: 8px 0 0 8px;
              cursor: pointer;
              &.active {
                cursor: not-allowed!important;
              }
            }

            .rightButton1{
              position: absolute;
              height: 36px;
              width: 36px;
              right: -18px;
              top: calc( 50% - 18px );
              z-index: 5;
              cursor: pointer;
            }
            .rightButton2{
              position: absolute;
              right: -26px;
              top: calc( 50% - 19px );
              height: 38px;
              width: 24px;
              background-color: #6C80B3;
              color: #ffffff;
              line-height: 38px;
              font-family: Inter, Inter;
              font-weight: bold;
              font-size: 12px;
              border-radius: 0 8px 8px 0;
              cursor: pointer;
            }
          }

          .coloredBG{
            background-image: url("../../assets/images/patentOAAi/coloredBG.png");
            background-position: left bottom;
            background-repeat: no-repeat;
            background-size: 100%, 100%;
          }
        }
      }

      .fullScreenShowPdfDialog{
        text-align: center;
        overflow-y: scroll;
        scrollbar-width: thin;
        height: 78vh;
      }
    }

  }

</style>
